import { userSlice } from 'entities/user';
import { useAppDispatch } from 'app/store/rootStore';
import { useMintModalMutation } from 'entities/user/model/api';

interface ModalDispatchOptions {
  modalName?: string;
  updateAction?: (isOpen: boolean) => any;
}

export const useModalDispatch = () => {
  const dispatch = useAppDispatch();
  const [mintModal] = useMintModalMutation();

  const handleMintModalUpdate = async (options: ModalDispatchOptions = {}) => {
    const {
      modalName = 'mintModal',
      updateAction = (isOpen: boolean) =>
        userSlice.actions.updateMintModal(isOpen),
    } = options;

    try {
      await mintModal(modalName)
        .unwrap()
        .then(() => {
          dispatch(updateAction(true));
        });
    } catch (error) {
      console.error(`Failed to update modal status for ${modalName}:`, error);
    }
  };

  return { handleMintModalUpdate };
};
