import { colors } from 'app/colors';
import { NavLink } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

const slideOutDown = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
    display: none;
  }
`;

const slideInUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
    display: flex;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
    
  }
`;

export const StyledLink = styled(NavLink)`
  width: 70px;
  height: 70px;
  will-change: transform;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  z-index: 4;
  font-size: 10px;
  align-items: center;
  height: 46px;
  justify-content: space-between;
  overflow: hidden;
  ${({ isOpen = true }: any) =>
    !isOpen &&
    css`
      display: none;
    `}
`;

//TODO one time anim
// ${({ isOpen }: any) =>
//   !isOpen
//     ? css`
//         animation: ${slideOutDown} 0.8s forwards;
//       `
//     : css`
//         animation: ${slideInUp} 0.3s forwards;
//       `}

export const Item = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  font-size: 10px;
  letter-spacing: 2px;
  color: ${({ isActive }) =>
    isActive ? 'var(--white-color)' : 'var(--text-inactive-color)'};
  align-items: center;
`;

export const StyledIcon = styled.svg<{ isActive: boolean }>`
  path {
    fill: ${({ isActive }) =>
      isActive ? 'var(--white-color)' : 'var(--text-inactive-color)'};
  }
`;
