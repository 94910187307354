import { boostsApi } from './api';
import { userApi } from 'entities/user';
import { Boost, BoostsState } from './types';
import { RootState } from 'app/store/rootStore';
import { isTimeStampExpired } from 'shared/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: BoostsState = {
  boosts: [],
  error: null,
  isLoading: false,
  activatedBoosts: [],
  maxBoostActivationTimes: 0,
};

const boostsSlice = createSlice({
  name: 'boosts',
  initialState,
  reducers: {
    setBoosts: (state, action: PayloadAction<Boost[]>) => {
      state.boosts = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.login.matchFulfilled,

      (state, { payload }) => {
        if (!!payload.boosts && !!payload.boosts.length) {
          state.activatedBoosts = Object.values(
            payload.boosts.reduce(
              (acc: any, el: Boost) =>
                el.id > (acc[el.type]?.id || 0)
                  ? { ...acc, [el.type]: el }
                  : acc,
              {}
            )
          );
        } else {
          state.activatedBoosts = [];
        }
      }
    );
    builder.addMatcher(
      boostsApi.endpoints.getBoosts.matchFulfilled,
      (state, { payload }) => {
        state.boosts = payload.data;
        state.maxBoostActivationTimes = payload.maxBoostActivationTimes;
      }
    );
    builder.addMatcher(
      boostsApi.endpoints.buyBoost.matchFulfilled,
      (state, { payload }) => {
        if (!!payload.accountsBoosts && !!payload.accountsBoosts.length) {
          state.activatedBoosts = Object.values(
            [...state.activatedBoosts, ...payload.accountsBoosts].reduce(
              (acc: any, el: Boost) =>
                el.id > (acc[el.type]?.id || 0)
                  ? { ...acc, [el.type]: el }
                  : acc,
              {}
            )
          );
        } else {
          state.activatedBoosts = [];
        }
      }
    );
  },
});

export const { setBoosts, setLoading, setError } = boostsSlice.actions;
export const boostsReducer = boostsSlice.reducer;

export const selectBoosts = (state: RootState) => state.boostsSlice.boosts;
export const selectBoostsError = (state: RootState) => state.boostsSlice.error;
export const selectBoostsLoading = (state: RootState) =>
  state.boostsSlice.isLoading;
export const selectActivatedBoosts = (state: RootState) =>
  state.boostsSlice.activatedBoosts;
export const selectMaxActivationLimit = (state: RootState) =>
  state.boostsSlice.maxBoostActivationTimes;
export const selectActivatedBoostsForGame = (state: RootState) => {
  return state.boostsSlice.activatedBoosts.filter(
    (boost) =>
      !isTimeStampExpired(boost.endTime, true) && boost.status === 'active'
  );
};

export const selectActivatedEnergyByDay = (state: RootState) => {
  return state.boostsSlice.activatedBoosts.find((el) => el.type === 'energy');
};
