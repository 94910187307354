import { useSelector } from 'react-redux';
import { BoostProgress } from './BoostProgress';
import { selectActivatedBoostsForGame } from '../model/slice';
import { GameBoostProgressWrapper } from './BoostItem.styled';

export const BoostPanelInGame = () => {
  const activeBoosts = useSelector(selectActivatedBoostsForGame);

  return (
    <GameBoostProgressWrapper>
      {activeBoosts &&
        activeBoosts.length > 0 &&
        activeBoosts.map((el) => <BoostProgress boost={el} />)}
    </GameBoostProgressWrapper>
  );
};
