export const getNextDayStartUTC = () => {
  const now = new Date();
  return new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate() + 1,
      0,
      1
    )
  );
};

export const isTimeStampExpired = (
  date: number,
  isTimeStampBySeconds: boolean
) => {
  const targetDate = new Date(isTimeStampBySeconds ? date * 1000 : date);

  return +targetDate < Date.now();
};
