export const formatNumber = (num: number): string => {
  if (!num) {
    return '0';
  }
  if (num >= 1_000_000_000) {
    return (num / 1_000_000_000)?.toFixed(1).replace(/\.0$/, '') + 'B'; // Миллиарды
  }

  if (num >= 1_000_000) {
    return (num / 1_000_000)?.toFixed(1).replace(/\.0$/, '') + 'M'; // Миллионы
  }

  if (num >= 1_0_000) {
    return (num / 1000)?.toFixed(1).replace(/\.0$/, '') + 'K'; // Тысячи
  }

  return num.toString(); // Обычное число с разделителями
};
