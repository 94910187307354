import { ROUTES } from 'app';
import { RootState } from 'app/store/rootStore';
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface ErrorContextProps {
  isOnline: boolean;
  globalError: string | null;
  clearError: () => void;
}

const ErrorContext = createContext<ErrorContextProps | null>(null);

interface ErrorProviderProps {
  children: ReactNode;
  useApiSelector: (state: RootState) => any; // Функция для получения глобальных ошибок из RTK Query
}

// Проверка поддержки WebGL
const isWebGLSupported = () => {
  try {
    const canvas = document.createElement('canvas');
    return !!(
      canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
    );
  } catch (error) {
    return false;
  }
};

// Проверка подключения к сети через периодический запрос
const checkConnection = async (setIsOnline: (status: boolean) => void) => {
  try {
    const response = await fetch('https://telegram.org/', { mode: 'no-cors' });
    setIsOnline(true);
  } catch {
    setIsOnline(false);
  }
};

export const ErrorProvider: React.FC<ErrorProviderProps> = ({
  children,
  useApiSelector,
}) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [globalError, setGlobalError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleOffline = () => {
    setIsOnline(false);
    navigate(
      `${ROUTES.ERROR}?title=Connection Error&desc=Please check your internet connection and try again&navigate=true`
    );
  };

  // Проверяем WebGL при монтировании
  useEffect(() => {
    if (!isWebGLSupported()) {
      setGlobalError('WebGL is not supported on this device');
      navigate(
        `${ROUTES.ERROR}?title=WebGL Error&desc=Your browser does not support WebGL. Please update your browser or enable WebGL`
      );
    }
  }, []);

  // Следим за изменением соединения с использованием периодической проверки
  useEffect(() => {
    const interval = setInterval(() => checkConnection(setIsOnline), 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!isOnline) {
      handleOffline();
    }
  }, [isOnline]);

  const apiError = useSelector(useApiSelector);
  useEffect(() => {
    if (apiError?.status >= 500) {
      navigate(
        `${ROUTES.ERROR}?title=Under Maintenance&desc=We're fixing things. Be back soon!`
      );
    }
    if (apiError?.error) {
      setGlobalError(apiError.error.message || 'Произошла ошибка запроса');
    }
  }, [apiError]);

  const clearError = () => setGlobalError(null);

  return (
    <ErrorContext.Provider value={{ isOnline, globalError, clearError }}>
      {children}
    </ErrorContext.Provider>
  );
};

export const useError = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error('useError must be used within an ErrorProvider');
  }
  return context;
};

export const selectUserApiError = (state: RootState) => {
  const queries = Object.values(state.userApi.queries);
  const mutations = Object.values(state.userApi.mutations);

  // Ищем первую ошибку в запросах или мутациях
  const errorEntry = [...queries, ...mutations].find((entry) => entry?.error);

  return errorEntry?.error || null;
};
