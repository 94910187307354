// src/entities/tasks/ui/Tasks.tsx
import { FC, useState, useMemo } from 'react';
import { TaskList } from '../Tasks.styled';
import { TaskItem } from './TaskItem';
import { ItemsListProps } from '../tasks.types';
import { selectPartnerRewardIds, selectRewardTaskIds } from '../slice';
import { useSelector } from 'react-redux';
import { selectMainQuests, selectPartnerQuests } from '../../user';

export const TasksContent: FC<ItemsListProps> = ({ items, case: caseType }) => {
  const [isCheckedTasks, setIsCheckedTasks] = useState<Set<number>>(new Set());
  const rewardTaskIds = useSelector(selectRewardTaskIds);
  const PartnerRewardIds = useSelector(selectPartnerRewardIds);
  const mainQuests = useSelector(selectMainQuests);
  const partnerTasks = useSelector(selectPartnerQuests);

  const memoizedTasks = useMemo(() => {
    return [...items].sort((a, b) => {
      const mainQuestA = mainQuests.find(
        (quest) => quest.mainQuestId === +a.id
      );
      const partnerQuestA = partnerTasks.find(
        (quest) => quest.partnersQuestId === +a.id
      );
      const mainQuestB = mainQuests.find(
        (quest) => quest.mainQuestId === +b.id
      );
      const partnerQuestB = partnerTasks.find(
        (quest) => quest.partnersQuestId === +b.id
      );

      const aIsInRewards =
        caseType === 'fetchMainTask'
          ? mainQuestA?.claimed || rewardTaskIds.includes(a.id)
          : partnerQuestA?.claimed || PartnerRewardIds.includes(a.id);

      const bIsInRewards =
        caseType === 'fetchMainTask'
          ? mainQuestB?.claimed || rewardTaskIds.includes(b.id)
          : partnerQuestB?.claimed || PartnerRewardIds.includes(b.id);

      if (aIsInRewards === bIsInRewards) return 0;
      return aIsInRewards ? 1 : -1;
    });
  }, [items, PartnerRewardIds, rewardTaskIds]);

  const handleCheckTask = (taskId: number) => {
    setIsCheckedTasks((prev) => new Set(prev).add(taskId));
  };

  return (
    <TaskList>
      {memoizedTasks.map((task) => (
        <TaskItem
          key={task.id}
          task={task}
          isChecked={isCheckedTasks.has(+task.id)}
          onCheck={handleCheckTask}
          case={caseType}
        />
      ))}
    </TaskList>
  );
};
