import { colors } from 'app/colors';
import { IconProps } from './icon.types';

export const EnergyIcon: React.FC<IconProps> = ({
  color = colors.fourthColor,
  width = 20,
  height = 20,
  size = 20,
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 16 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.4302 1.57865L9.22552 7.57593L9.09963 8.20265L9.69849 8.42625L14.969 10.3941L5.56973 20.4214L6.77436 14.424L6.90025 13.7973L6.3014 13.5737L1.031 11.6058L10.4302 1.57865ZM11.0476 2.1112e-10C11.0257 2.1112e-10 11.0038 0.00186734 10.9821 0.00564194C10.9004 0.0198307 10.8256 0.0601407 10.7691 0.120472L0.102434 11.4998C0.0597038 11.5454 0.0289239 11.6007 0.012814 11.661C-0.00329619 11.7213 -0.00422626 11.7845 0.0100836 11.8453C0.024394 11.906 0.053524 11.9622 0.0948739 12.0091C0.136234 12.0559 0.188554 12.0918 0.247194 12.1138L6.03905 14.2763L4.5788 21.5462C4.56776 21.6013 4.56911 21.658 4.58276 21.7125C4.59641 21.767 4.62201 21.8177 4.65773 21.8611C4.69345 21.9045 4.73838 21.9394 4.7893 21.9635C4.84023 21.9875 4.89588 22 4.95223 22C5.00449 22 5.05619 21.9894 5.10413 21.9686C5.15207 21.9479 5.19522 21.9176 5.23087 21.8795L15.8975 10.5002C15.9403 10.4546 15.971 10.3993 15.9872 10.339C16.0032 10.2787 16.0043 10.2154 15.9899 10.1547C15.9756 10.094 15.9465 10.0378 15.9051 9.99093C15.8638 9.9441 15.8114 9.90809 15.7528 9.88624L9.96083 7.72363L11.4211 0.453701C11.4374 0.372782 11.4267 0.288791 11.3907 0.214462C11.3547 0.14014 11.2953 0.0795421 11.2215 0.041851C11.1674 0.0142059 11.1078 -1.99979e-06 11.0476 2.1112e-10Z'
      fill={color}
    />
  </svg>
);
