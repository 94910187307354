import { ROUTES } from 'app';
import { Timer } from 'shared/timer';
import { colors } from 'app/colors';
import { Button } from 'shared/button';
import { useModal } from 'shared/modal';
import { Text } from 'shared/typography';
import { useSelector } from 'react-redux';
import { Gradient } from 'shared/gradient';
import { useMintingHook } from 'app/hooks';
import { use3dModelContext } from 'feature/3d';
import { StyledText } from './LaunchDate.styled';
import { SintReadyModal } from './sintReadyModal';
import { selectUser } from 'entities/user';
import { userSlice } from 'entities/user';
import { CharacterStats } from 'widget/characterStats';
import { NavLink, useNavigate } from 'react-router-dom';
import { CentredWrapper, Scene3dContainer } from 'shared/layout';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useModalDispatch } from 'shared/utils/modalDispatchHelper';

export const LaunchDate = () => {
  const { accountReferralInfo, mintModal: isMintModalShown } =
    useSelector(selectUser);

  const { mintingStartedDate: mintDate } = useMintingHook();

  const { mount3dScene, unmount3dScene, movementControllerOfModel } =
    use3dModelContext();
  const sceneContainerLaunchPage = useRef<HTMLDivElement | null>(null);
  const [isTimeUp, setIsTimeUp] = useState(false);
  const { setIsOpen, setModalProps } = useModal();
  const navigate = useNavigate();
  const { handleMintModalUpdate } = useModalDispatch();
  const modalShownRef = useRef(false);

  const targetDate = useMemo(() => {
    const durationMintMinutes = process.env.REACT_APP_MINT_DURATION || 60000;
    return new Date(+mintDate + +durationMintMinutes);
  }, [mintDate]);

  const hasAvailableInvites = useMemo(() => {
    if (!accountReferralInfo) return false;
    return (
      accountReferralInfo.maxNumberOfAccounts -
        accountReferralInfo.invitedCount >
      0
    );
  }, [accountReferralInfo]);

  useLayoutEffect(() => {
    const checkTimeAndShowModal = () => {
      if (isMintModalShown || modalShownRef.current) {
        if (isTimeUp) {
          navigate(ROUTES.GAME);
        }
        return;
      }

      if (isTimeUp) {
        modalShownRef.current = true;
        setModalProps({
          children: (
            <SintReadyModal
              onClose={() => {
                handleMintModalUpdate({
                  modalName: 'mintModal',
                  updateAction: (isOpen) =>
                    userSlice.actions.updateMintModal(isOpen),
                });
                setIsOpen(false);
              }}
            />
          ),
          onRequestClose: () => {
            handleMintModalUpdate({
              modalName: 'mintModal',
              updateAction: (isOpen) =>
                userSlice.actions.updateMintModal(isOpen),
            });
            setIsOpen(false);
          },
          isOpen: true,
        });
        setIsOpen(true);
        navigate(ROUTES.GAME);
      }
    };
    checkTimeAndShowModal();
  }, [isTimeUp, isMintModalShown]);

  useEffect(() => {
    if (sceneContainerLaunchPage && sceneContainerLaunchPage.current) {
      mount3dScene(sceneContainerLaunchPage.current.id);
    }
  }, [sceneContainerLaunchPage]);

  useEffect(() => {
    if (
      movementControllerOfModel &&
      sceneContainerLaunchPage &&
      sceneContainerLaunchPage.current
    ) {
      if (isTimeUp) {
        movementControllerOfModel.moveSintLaunchPage();
      } else {
        movementControllerOfModel.moveHologramWithOpacityToLaunchPage(
          mintDate,
          targetDate
        );
      }
    }
  }, [movementControllerOfModel, isTimeUp]);

  useLayoutEffect(() => {
    return () => {
      console.log('SCENE-UNMOUNT');
      unmount3dScene();
    };
  }, []);

  return (
    <CentredWrapper>
      <CharacterStats />
      <Scene3dContainer
        id={'3d-scene-launch-page'}
        ref={sceneContainerLaunchPage}
        style={{
          width: '100%',
          height: '350px',
        }}
      />

      <StyledText>
        {isTimeUp ? (
          <>
            Train Your SYNTHETIC INTELLIGENCE,
            <br />
            Own the Future
          </>
        ) : (
          'Minting in progress'
        )}
      </StyledText>
      {!isTimeUp && <Timer targetDate={targetDate} onTimeUp={setIsTimeUp} />}
      <NavLink
        style={{ marginTop: 10 }}
        to={hasAvailableInvites ? ROUTES.REFERRAL : ROUTES.TASKS}
      >
        <Button decoration='basic' borderColor={colors.primaryColor} size='l'>
          <Gradient color={colors.primaryColor}>
            <Text>{hasAvailableInvites ? 'INVITE' : 'VIEW TASKS'}</Text>
          </Gradient>
        </Button>
      </NavLink>
    </CentredWrapper>
  );
};
