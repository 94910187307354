import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/store/rootStore';
import {
  setSelectedItems,
  incrementStep,
  setExpired,
  setCurrentQuestion,
  resetQuizState,
} from 'entities/quiz';
import { updateBalance } from 'entities/user';
import { colors } from 'app/colors';
import { Text } from 'shared/typography';
import { Gradient } from 'shared/gradient';
import { Loader } from 'shared/loader';
import { ROUTES } from 'app/router';
import {
  QuizGrid,
  ColorGrid,
  ColorCircle,
  StyledButton,
  SubmitContainer,
  StyledSubmitButton,
  QuizWrapper,
  QuizHeader,
  ImageContainer,
  QuizTitle,
  StepCounter,
  TimeBlock,
  QuizContent,
} from './QuizQuestion.styled';
import { addMinutes } from 'date-fns';
import { useToast } from 'shared/toast/ToastContext';
import { Timer } from 'shared/timer/Timer';
import { useSubmitAnswerMutation } from 'entities/quiz/model/api';
import { useModal } from 'shared/modal/ModalContext';
import { QuizCompleteModal } from './modal/QuizCompleteModal';
import { updateUserQuizzes } from 'entities/user/model/slice';
import { CheckmarkIcon } from 'shared/icon/Checkmark';
import { QuestionType } from './QuizQuestion.types';

export const QuizQuestion = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showToast } = useToast();
  const [submitAnswer] = useSubmitAnswerMutation();
  const { setIsOpen, setModalProps } = useModal();
  const userQuizzes = useAppSelector((state) => state.user.account.quizzes);
  const currentBalance = useAppSelector(
    (state) => state.user.account.walletBalance
  );
  const activeQuiz = useAppSelector((state) => state.quiz.activeQuiz);
  const currentQuestion = useAppSelector((state) => state.quiz.currentQuestion);
  const selectedItems = useAppSelector((state) => state.quiz.selectedItems);
  const currentStep = useAppSelector((state) => state.quiz.currentStep);
  const totalSteps = useAppSelector((state) => state.quiz.totalSteps);
  // const isExpired = useAppSelector((state) => state.quiz.isExpired);
  const quizzes = useAppSelector((state) => state.quiz.quizzes);

  // const handleTimeUp = useCallback(() => {
  //   if (!isExpired) {
  //     dispatch(setExpired(true));
  //     showToast('Time is up', 'warn');
  //   }
  // }, [isExpired, dispatch, showToast]);

  useEffect(() => {
    if (!activeQuiz || !quizzes.length) {
      dispatch(resetQuizState());
      navigate(ROUTES.QUIZ);
      return;
    }
  }, [activeQuiz, quizzes, dispatch, navigate]);

  useEffect(() => {
    if (activeQuiz && !currentQuestion) {
      const activeQuestion = activeQuiz.questions[currentStep - 1];

      if (!activeQuestion) {
        console.error(
          'No active question found, redirecting to quiz selection'
        );
        dispatch(resetQuizState());
        navigate(ROUTES.QUIZ);
        return;
      }

      if (activeQuestion.status === 'completed') {
        if (currentStep < totalSteps) {
          dispatch(incrementStep());
          return;
        } else {
          dispatch(resetQuizState());
          navigate(ROUTES.QUIZ);
          return;
        }
      }

      const quiz = quizzes.find((q) => q.id === activeQuiz.quizId);
      if (!quiz) {
        console.error('Quiz not found, redirecting to quiz selection');
        dispatch(resetQuizState());
        navigate(ROUTES.QUIZ);
        return;
      }

      const fullQuestion = quiz.questions.find(
        (q) => q.id === activeQuestion.quizQuestionId
      );

      if (fullQuestion) {
        dispatch(setCurrentQuestion(fullQuestion));
      } else {
        console.error(
          'Question details not found, redirecting to quiz selection'
        );
        dispatch(resetQuizState());
        navigate(ROUTES.QUIZ);
      }
    }
  }, [activeQuiz, currentStep, currentQuestion, quizzes, dispatch, navigate]);

  if (!activeQuiz || !currentQuestion) return <Loader />;

  const isColorMode =
    currentQuestion.answers?.some((answer) => answer.type === 'color') || false;
  const isSubmitEnabled = selectedItems.length > 0;

  const handleSelect = (answerId: number) => {
    switch (currentQuestion.type as QuestionType) {
      case 'single_choice':
        if (selectedItems.includes(answerId)) {
          dispatch(setSelectedItems([]));
        } else {
          dispatch(setSelectedItems([answerId]));
        }
        break;

      case 'multiple_choice':
        if (selectedItems.includes(answerId)) {
          const newItems = selectedItems.filter((id) => id !== answerId);
          dispatch(setSelectedItems(newItems));
        } else {
          const newItems = [...selectedItems, answerId];
          dispatch(setSelectedItems(newItems));
        }
        break;

      default:
        console.warn('Unknown question type:', currentQuestion.type);
    }
  };

  const handleSubmit = async () => {
    try {
      if (activeQuiz && selectedItems.length > 0 && currentQuestion) {
        const activeQuestion = activeQuiz.questions[currentStep - 1];

        if (!activeQuestion) {
          console.error('No active question found for step:', currentStep);
          return;
        }

        const response = await submitAnswer({
          quizId: activeQuiz.id,
          questionId: activeQuestion.id,
          answerId:
            currentQuestion.type === 'multiple_choice'
              ? selectedItems
              : selectedItems[0],
        }).unwrap();

        if (response.status === 'claimed') {
          setIsOpen(true);
          setModalProps({
            children: (
              <QuizCompleteModal
                reward={response.reward}
                onComplete={() => {
                  dispatch(resetQuizState());
                  setIsOpen(false);
                  navigate(ROUTES.QUIZ);
                }}
              />
            ),
            onRequestClose: () => {
              dispatch(resetQuizState());
              setIsOpen(false);
              navigate(ROUTES.QUIZ);
            },
          });
          const updatedQuizzes = userQuizzes.map((quiz) =>
            quiz.quizId === activeQuiz.quizId
              ? { ...quiz, status: 'claimed' }
              : quiz
          );
          dispatch(updateUserQuizzes(updatedQuizzes));
          dispatch(updateBalance(currentBalance + response.reward));
        } else if (currentStep < totalSteps) {
          dispatch(incrementStep());
          dispatch(setSelectedItems([]));
        }
      }
    } catch (error) {
      console.error('Failed to submit answer:', error);
      showToast('Failed to submit answer', 'error');
    }
  };

  // const getExpirationDate = (minutes: number) => {
  //   return addMinutes(new Date(), minutes);
  // };

  return (
    <QuizWrapper>
      <QuizHeader>
        <ImageContainer>
          {currentQuestion.image && (
            <img
              src={`${process.env.REACT_APP_API_IMG_URL}/${currentQuestion.image}`}
              alt={currentQuestion.name}
            />
          )}
        </ImageContainer>
        <StepCounter>
          {currentStep}/{totalSteps}
        </StepCounter>
      </QuizHeader>
      <QuizContent>
        <QuizTitle>{currentQuestion.name}</QuizTitle>
        {isColorMode ? (
          <ColorGrid>
            {currentQuestion.answers?.map((answer) => {
              const isSelected = selectedItems.includes(answer.id);
              return (
                <ColorCircle
                  key={answer.id}
                  color={answer.text}
                  isSelected={isSelected}
                  onClick={() => handleSelect(answer.id)}
                >
                  {isSelected && (
                    <CheckmarkIcon
                      width={24}
                      height={24}
                      color={answer.text}
                      border={colors.whiteColor}
                      borderWidth={1.5}
                    />
                  )}
                </ColorCircle>
              );
            })}
          </ColorGrid>
        ) : (
          <QuizGrid>
            {currentQuestion.answers?.map((answer) => {
              const isSelected = selectedItems.includes(answer.id);
              return (
                <StyledButton
                  key={answer.id}
                  onClick={() => handleSelect(answer.id)}
                  isSelected={isSelected}
                  decoration={isSelected ? 'basic' : undefined}
                  borderColor={
                    isSelected
                      ? colors.secondaryColor
                      : colors.notIntensiveColor
                  }
                  size='l'
                >
                  {answer.text}
                </StyledButton>
              );
            })}
          </QuizGrid>
        )}

        {/* <TimeBlock>
          {isExpired ? (
            'EXPIRED'
          ) : (
            <>
              EXPIRATION:
              <Timer
                basic
                targetDate={getExpirationDate(currentQuestion?.expire || 0)}
                onTimeUp={handleTimeUp}
              />
            </>
          )}
        </TimeBlock> */}

        <SubmitContainer>
          <StyledSubmitButton
            onClick={handleSubmit}
            decoration='basic'
            borderColor={colors.primaryColor}
            size='l'
            disabled={!isSubmitEnabled}
          >
            <Gradient color={colors.primaryColor} displayed={isSubmitEnabled}>
              <Text>SUBMIT</Text>
            </Gradient>
          </StyledSubmitButton>
        </SubmitContainer>
      </QuizContent>
    </QuizWrapper>
  );
};
