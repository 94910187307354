import { useNavigate } from 'react-router-dom';
import { BoostText, Wrapper } from './Boost.styled';
import { CentredWrapper } from 'shared/layout';
import { Rocket } from 'shared/icon/Rocket';
import { ROUTES } from 'app/router/routes';
import { useSelector } from 'react-redux';
import { selectActivatedBoostsForGame } from '../../../entities/boosts/model/slice';

export const BoostBtn = ({ closeGame }: { closeGame: () => void }) => {
  const navigate = useNavigate();
  const activeBoosts = useSelector(selectActivatedBoostsForGame);

  const handleClick = () => {
    closeGame();
    navigate(ROUTES.BOOSTS);
  };

  return (
    <Wrapper onClick={handleClick}>
      <CentredWrapper>
        <Rocket isOutlined={!activeBoosts.length} />
      </CentredWrapper>
      <BoostText>Boost</BoostText>
    </Wrapper>
  );
};
