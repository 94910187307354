import { Fonts } from 'app/Fonts';
import { store } from 'app/store';

import { Model3dProvider } from 'feature/3d';
import { AuthProvider } from 'feature/auth';
import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { ToastProvider } from 'shared/toast/ToastProvider';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'animate.css';
import { ModalProvider } from 'shared/modal';
import GlobalStyle from 'app/GlobalStyles';
import { TonConnectUIProvider } from '@tonconnect/ui-react';

import { EnergyProvider } from 'feature/game/energy';
import { DeviceProvoider } from 'feature/deviceProvoider';
import { ErrorProvider } from 'feature/error';
import { userApi } from 'entities/user';
import { selectUserApiError } from 'feature/error/ErrorProvoider';

interface AppProvidersProps {
  children: ReactNode;
}

export const AppProviders = ({ children }: AppProvidersProps) => {
  Fonts();

  return (
    <>
      <GlobalStyle />

      {/*<DeviceProvoider>*/}
      <Provider store={store}>
        <ErrorProvider useApiSelector={selectUserApiError}>
          <TonConnectUIProvider manifestUrl='https://tg.sint.game/tonconnect-manifest.json'>
            <ToastProvider>
              <ModalProvider>
                <ToastContainer />
                <Model3dProvider>
                  <AuthProvider>
                    <EnergyProvider>{children}</EnergyProvider>
                  </AuthProvider>
                </Model3dProvider>
              </ModalProvider>
            </ToastProvider>
          </TonConnectUIProvider>
        </ErrorProvider>
      </Provider>
      {/*</DeviceProvoider>*/}
    </>
  );
};
