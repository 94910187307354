import { Button } from 'shared/button';
import styled from 'styled-components';

export const SegmentWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  color: black;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin: 2px 0;

  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
    transform: scale(1);
  }
`;

export const Position = styled.div`
  min-width: 20%;
  max-width: 20%;
  font-size: 20px;
`;
export const Name = styled.div`
  width: 55%;
  font-size: 16px;
  text-align: start;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 25ch;
`;

export const IconWrapper = styled.div`
  margin-left: 5px;
`;
export const Balance = styled.div`
  width: 45%;
  font-size: 18px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  justify-content: flex-end;
  text-align: end;
`;

export const UserRatingPositionWrapper = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 2%;
  background: rgba(39, 31, 78, 1);
  border: 1px solid rgba(216, 243, 79, 1);
  margin-bottom: 8px;
`;

export const UserRatingPos = styled.span`
  min-width: 20%;
  max-width: 20%;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
