export interface TelegramUser {
  id: number;
  first_name: string;
  last_name?: string;
  username?: string;
  language_code?: string;
  allows_write_to_pm?: boolean;
}

export interface UseTelegramAuthReturn {
  user: TelegramUser | null;
  initData: string | null;
  loading: boolean;
}

export enum ImpactStyle {
  LIGHT = 'light',
  MEDIUM = 'medium',
  HEAVY = 'heavy',
  RIGID = 'rigid',
  SOFT = 'soft',
}

export enum NotificationType {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}
