import { ROUTES } from 'app';
import { colors } from 'app/colors';
import { Button } from 'shared/button';
import { useEffect, useRef } from 'react';
import { useMintingHook } from 'app/hooks';
import { Gradient } from 'shared/gradient';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectUser, selectIsMinted } from 'entities/user';
import { useAppSelector } from 'app/store/rootStore';
import { Item, Wrapper, StyledIcon, StyledLink } from './Menu.styled';
import { Logo, ProfileIcon, RaitingIcon, TasksIcon } from 'shared/icon';
import {
  selectMenuVisability,
  selectorIs3dSceneMounting,
} from 'entities/settings';
import { useModal } from 'shared/modal';
import { SintReadyModal } from 'widget/launchDate/sintReadyModal';
import { AiIcon } from '../../shared/icon/AI_icon';
import { useModalDispatch } from 'shared/utils/modalDispatchHelper';
import { userSlice } from 'entities/user';

export const Menu = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { setIsOpen, setModalProps } = useModal();
  const { handleMintModalUpdate } = useModalDispatch();
  const isGame = pathname === ROUTES.GAME;
  const isMinted = useAppSelector(selectIsMinted);
  const { mintModal: isMintModalShown } = useAppSelector(selectUser);
  const menuRef = useRef(null);
  const isMenu = useAppSelector(selectMenuVisability);
  const isSceneMounting = useAppSelector(selectorIs3dSceneMounting);

  const { isSintReady: isTargetDateAlready } = useMintingHook();

  useEffect(() => {
    if (menuRef && menuRef.current) {
      let lastTouchTime = 0;

      menuRef.current.addEventListener(
        'touchstart',
        (e) => {
          const currentTime = new Date().getTime();
          const timeDiff = currentTime - lastTouchTime;

          if (timeDiff < 200) {
            e.preventDefault();
          }

          lastTouchTime = currentTime;
        },
        { passive: false }
      );
    }
  }, [menuRef]);

  const handleGameNavigation = (e) => {
    e.preventDefault();

    if (isTargetDateAlready && !isMintModalShown) {
      setModalProps({
        children: (
          <SintReadyModal
            onClose={() => {
              handleMintModalUpdate({
                modalName: 'mintModal',
                updateAction: (isOpen) =>
                  userSlice.actions.updateMintModal(isOpen),
              });
              setIsOpen(false);
            }}
          />
        ),
        onRequestClose: () => {
          handleMintModalUpdate({
            modalName: 'mintModal',
            updateAction: (isOpen) => userSlice.actions.updateMintModal(isOpen),
          });
          setIsOpen(false);
        },
        isOpen: true,
      });
      setIsOpen(true);
    }

    navigate(
      isTargetDateAlready && isMinted
        ? ROUTES.GAME
        : isMinted
          ? ROUTES.LAUNCH
          : ROUTES.MINT
    );
  };

  return (
    <Wrapper
      ref={menuRef}
      isOpen={isMenu}
      style={{
        pointerEvents: isSceneMounting ? 'none' : 'auto',
      }}
    >
      <StyledLink
        to={
          isTargetDateAlready && isMinted
            ? ROUTES.GAME
            : isMinted
              ? ROUTES.LAUNCH
              : ROUTES.MINT
        }
        onClick={handleGameNavigation}
        style={{
          textDecoration: 'none',
          pointerEvents:
            pathname === ROUTES.GAME ||
            pathname === ROUTES.MINT ||
            pathname === ROUTES.LAUNCH
              ? 'none'
              : 'auto',
        }}
      >
        {({ isActive }) => (
          <Button size='l' shape='round'>
            <Gradient
              displayed={isActive || isGame}
              isFull={false}
              color={colors.primaryColor}
            >
              <Item isActive={isActive || isGame}>
                <StyledIcon as={Logo} isActive={isActive || isGame} />
                SINT
              </Item>
            </Gradient>
          </Button>
        )}
      </StyledLink>

      <StyledLink to={ROUTES.TASKS} style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <Button size='l' shape='round'>
            <Gradient
              displayed={isActive}
              isFull={false}
              color={colors.primaryColor}
            >
              <Item isActive={isActive}>
                <StyledIcon as={TasksIcon} isActive={isActive} />
                TASKS
              </Item>
            </Gradient>
          </Button>
        )}
      </StyledLink>
      <StyledLink to={ROUTES.QUIZ} style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <Button size='l' shape='round'>
            <Gradient
              displayed={isActive}
              isFull={false}
              color={colors.primaryColor}
            >
              <Item isActive={isActive}>
                <StyledIcon
                  as={AiIcon}
                  isActive={isActive}
                  height={24}
                  width={24}
                />
                MINING
              </Item>
            </Gradient>
          </Button>
        )}
      </StyledLink>
      <StyledLink to={ROUTES.RATINGS} style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <Button size='l' shape='round'>
            <Gradient
              displayed={isActive}
              isFull={false}
              color={colors.primaryColor}
            >
              <Item isActive={isActive}>
                <StyledIcon as={RaitingIcon} isActive={isActive} />
                RATING
              </Item>
            </Gradient>
          </Button>
        )}
      </StyledLink>

      <StyledLink to={ROUTES.PROFILE} style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <Button size='l' shape='round'>
            <Gradient
              displayed={isActive}
              isFull={false}
              color={colors.primaryColor}
            >
              <Item isActive={isActive}>
                <StyledIcon as={ProfileIcon} isActive={isActive} />
                PROFILE
              </Item>
            </Gradient>
          </Button>
        )}
      </StyledLink>
    </Wrapper>
  );
};
