import React from 'react';
import { useInterval } from 'react-use';

import { useAppDispatch, useAppSelector } from 'app/store/rootStore';
import {
  selectEnergy,
  selectEnergyRecoverySec,
  updateEnergy,
} from 'entities/user';

export const EnergyProvider = ({ children }: { children: React.ReactNode }) => {
  const energy = useAppSelector(selectEnergy);
  const durationSec = useAppSelector(selectEnergyRecoverySec);

  const dispatch = useAppDispatch();

  // Автообновление энергии каждые 60 секунды
  useInterval(() => {
    dispatch(updateEnergy(Math.min(energy + 1, 500)));
  }, durationSec * 1000);
  return <>{children}</>;
};
