import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Question, Quiz } from 'widget/quizList/types';
import { SelectQuizResponse, QuizState, QuizStatusType } from './types';
import { RootState } from 'app/store/rootStore';
import { userApi } from 'entities/user/model/api';
import { quizApi } from 'entities/quiz/model/api';

const initialState: QuizState = {
  currentStep: 1,
  totalSteps: 0,
  selectedItems: [],
  activeQuiz: null,
  currentQuestion: null,
  isLoading: false,
  error: null,
  isExpired: false,
  quizzes: [],
  quizStatuses: {},
};

export const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    setQuizzes: (state, action: PayloadAction<Quiz[]>) => {
      state.quizzes = action.payload;
    },
    setActiveQuiz: (state, action: PayloadAction<SelectQuizResponse>) => {
      state.activeQuiz = action.payload;
      state.currentStep = 1;
      state.selectedItems = [];
      state.isExpired = false;
      state.totalSteps = action.payload.questions.length;
    },
    setSelectedItems: (state, action: PayloadAction<number[]>) => {
      state.selectedItems = action.payload;
    },
    incrementStep: (state) => {
      if (state.currentStep < state.totalSteps && state.activeQuiz) {
        state.currentStep += 1;
        state.currentQuestion = null;
        state.selectedItems = [];
      }
    },
    setExpired: (state, action: PayloadAction<boolean>) => {
      state.isExpired = action.payload;
    },
    setCurrentQuestion: (state, action: PayloadAction<Question>) => {
      state.currentQuestion = action.payload;
    },
    resetQuizState: (state) => {
      state.currentStep = 1;
      state.totalSteps = 0;
      state.selectedItems = [];
      state.activeQuiz = null;
      state.currentQuestion = null;
      state.isExpired = false;
    },
    updateQuizStatus: (
      state,
      action: PayloadAction<{ quizId: number; status: QuizStatusType }>
    ) => {
      state.quizStatuses[action.payload.quizId] = action.payload.status;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        if (payload.quizzes?.length) {
          state.quizStatuses = payload.quizzes.reduce(
            (
              acc: { [key: number]: QuizStatusType },
              quiz: { quizId: number; status: QuizStatusType }
            ) => ({
              ...acc,
              [quiz.quizId]: quiz.status,
            }),
            {}
          );
        }
      }
    );

    builder.addMatcher(
      quizApi.endpoints.submitAnswer.matchFulfilled,
      (state, { payload }) => {
        if (payload.status === 'claimed' && state.activeQuiz) {
          state.quizStatuses[state.activeQuiz.quizId] = 'claimed';
        }
      }
    );
  },
});

export const {
  setActiveQuiz,
  setSelectedItems,
  incrementStep,
  setExpired,
  setCurrentQuestion,
  setQuizzes,
  resetQuizState,
  updateQuizStatus,
} = quizSlice.actions;

export const selectQuizzes = (state: RootState) => state.user.account.quizzes;
export const selectQuizById = (quizId: number) => (state: RootState) =>
  state.user.account.quizzes?.find((quiz) => quiz.quizId === quizId);
export const selectQuizStatus = (quizId: number) => (state: RootState) =>
  state.quiz.quizStatuses[quizId];

export const quizReducer = quizSlice.reducer;
