import {
  Balance,
  IconWrapper,
  Name,
  Position,
  StyledButton,
} from './LeaderBoard.styled';
import {
  useLeaderboardDailyMutation,
  useLeaderboardMutation,
  useLeaderboardReferralsMutation,
} from 'entities/user/model/api';
import { Soon } from 'shared/soon';
import { Tabs } from 'shared/tabs';
import { colors } from 'app/colors';
import { Loader } from 'shared/loader';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { formatNumber } from 'shared/utils';
import { CircleBg } from 'feature/game/balance';
import { CoinIcon, LeaderIcon } from 'shared/icon';
import { CentredWrapper, H100 } from 'shared/layout';
import { useAppSelector } from 'app/store/rootStore';
import { UserRatingPosition } from './UserRatingPosition';
import { LeaderBoardItem } from 'entities/user/model/types';
import { selectLeaderboard, selectUser } from 'entities/user';

const getBorderColor = (index: number) => {
  if (index < 1) return colors.secondaryColor;
  if (index < 3) return colors.whiteColor;
  return colors.tertiaryColor;
};

const getBackgroundColor = (index: number) =>
  index < 1 ? '#D8F34F1A' : index < 3 ? colors.listItemBG : 'transperent';

const getBgFilter = (index: number) =>
  index > 0 && index < 3 ? 'blur(10px)' : 'none';

const getBgColor = (index: number) =>
  index < 1 ? colors.secondaryColor : colors.whiteColor;

const getDecoration = (index: number) => (index < 3 ? 'basic' : undefined);

const getPosDecoration = (index: number) => {
  if (index < 1) return colors.secondaryColor;
  if (index < 3) return colors.whiteColor;
  return colors.transperent;
};

const renderTabContent = (
  tab: 'GLOBAL' | 'REFERRALS' | 'DAILY',
  data: any[]
) => {
  const slicedData = data.length > 100 ? data.slice(0, 100) : data;

  return (
    <H100>
      {slicedData.map(({ username, amount, id }, index) => (
        <StyledButton
          key={`${tab}-${index}`}
          borderSize={index < 3 ? 0 : 0.5}
          borderColor={getBorderColor(index)}
          color={getBackgroundColor(index)}
          decoration={getDecoration(index)}
          style={{
            color: getBgColor(index),
            backdropFilter: getBgFilter(index),
          }}
        >
          <Position>
            {' '}
            <LeaderIcon value={index + 1} color={getPosDecoration(index)} />
          </Position>
          <Name>{username || 'user_' + id}</Name>
          <Balance>
            {formatNumber(amount)}
            {tab !== 'REFERRALS' && (
              <IconWrapper>
                <CentredWrapper>
                  {' '}
                  <CircleBg>
                    <CoinIcon />
                  </CircleBg>
                </CentredWrapper>
              </IconWrapper>
            )}
          </Balance>
        </StyledButton>
      ))}
    </H100>
  );
};

const UserRatingPositionOnLeaderboard = (
  data: LeaderBoardItem[],
  userID: number
) => {
  const userData = data.find((el) => el.id === userID);

  const userPosition = userData
    ? (userData.position ?? data.findIndex((el) => el.id === userData.id) + 1)
    : 0;

  if (userPosition <= 10 || !userData) return null;

  return (
    <UserRatingPosition
      position={userPosition}
      userID={userData.id}
      amount={userData.amount}
      title={userData.username}
    />
  );
};

export const LeaderBoardTabs = () => {
  const user = useSelector(selectUser);
  const [getLeaderboard, { isLoading: loading }] = useLeaderboardMutation();
  const [getLeaderboardDaily, { isLoading: isDailyLoading }] =
    useLeaderboardDailyMutation();
  const [getLeaderboardReferrals, { isLoading: isReferralsLoading }] =
    useLeaderboardReferralsMutation();

  useEffect(() => {
    getLeaderboard('');
    getLeaderboardDaily('');
    getLeaderboardReferrals('');
  }, []);

  const { top, daily, referrals } = useAppSelector(selectLeaderboard);

  const isData = top.length > 0;

  const tabItems = [
    {
      title: 'DAILY',
      segment:
        !isDailyLoading || isData ? (
          renderTabContent('DAILY', daily)
        ) : (
          <H100>
            <Loader />
          </H100>
        ),
      disable: false,
    },
    {
      title: 'GLOBAL',
      segment:
        !loading || isData ? (
          renderTabContent('GLOBAL', top)
        ) : (
          <H100>
            {' '}
            <Loader />
          </H100>
        ),
      disable: false,
    },
    {
      title: 'REFERRALS',
      segment:
        !isReferralsLoading || isData ? (
          <H100>
            <Soon
              title='Coming soon...'
              descr='Referral rating will be added in the nearest future'
            />
          </H100>
        ) : (
          <H100>
            {' '}
            <Loader />
          </H100>
        ),

      disable: false,
    },
  ];

  const tabUserPos = [
    {
      title: 'DAILY',
      segment:
        !isDailyLoading || isData
          ? UserRatingPositionOnLeaderboard(daily as LeaderBoardItem[], user.id)
          : null,
      disable: false,
    },
    {
      title: 'GLOBAL',
      segment:
        !loading || isData
          ? UserRatingPositionOnLeaderboard(top as LeaderBoardItem[], user.id)
          : null,
      disable: false,
    },
    {
      title: 'REFERRALS',
      segment: null,
      disable: false,
    },
  ];

  return (
    <Tabs
      defaultActiveTab='GLOBAL'
      items={tabItems}
      additionalItems={tabUserPos}
    />
  );
};
