import { colors } from 'app/colors';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-use';
import { Button } from 'shared/button';
import { Gradient } from 'shared/gradient';

import {
  BlockContent,
  BlockWrapper,
  BounceKeyFrame,
  CentredWrapper,
  TextContainer,
} from 'shared/layout';
import { Logo } from 'shared/logo';
import { Text } from 'shared/typography';
import { ButtonWrapper } from 'widget/profile/Profile.styled';
import { useError } from './ErrorProvoider';
import { ToastIcon } from 'shared/icon';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const ErrorContent = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const { isOnline } = useError();

  const title = query.get('title');
  const icon = query.get('icon');
  const description = query.get('desc');
  const isGoBack = query.get('navigate');

  console.log(isGoBack);
  console.log(isOnline);
  console.log({ title, icon, description });
  return (
    <CentredWrapper
      style={{
        width: '100%',
      }}
    >
      <BlockWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '12px',

            marginBottom: 20,
          }}
        >
          <ToastIcon />
        </div>
        <BlockContent
          style={{
            marginTop: '12px',
          }}
        >
          <Text
            style={{
              color: colors.secondaryColor,
            }}
          ></Text>
          <TextContainer
            style={{
              marginTop: '5px',
            }}
          ></TextContainer>
        </BlockContent>
      </BlockWrapper>

      <BlockWrapper>
        <BlockContent>
          <Text
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '20px',
              lineHeight: '28px',
              gap: '10px',
            }}
          >
            {title}
          </Text>
        </BlockContent>
        <BlockContent
          style={{
            marginTop: '12px',
          }}
        >
          <Text
            style={{
              fontSize: '14px',
              marginTop: '2px',
              lineHeight: '20px',
              marginBottom: '20px',
            }}
          >
            {description}
          </Text>
        </BlockContent>
      </BlockWrapper>

      <BlockWrapper>
        {isOnline && isGoBack ? (
          <ButtonWrapper>
            <Button
              onClick={() => navigate(-1)}
              color={colors.notIntensiveColor}
              borderColor={colors.primaryColor}
              decoration='basic'
              style={{ height: '56px', width: '160px', padding: '0px' }}
            >
              <Gradient color={colors.primaryColor}>
                <Text>Back</Text>
              </Gradient>
            </Button>
          </ButtonWrapper>
        ) : null}
      </BlockWrapper>
    </CentredWrapper>
  );
};
