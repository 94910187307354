import { Header, PageContent, PageWrapper } from 'shared/layout';
import { Balance } from 'feature/game/balance';
import { Menu } from 'widget/menu';
import { QuizQuestion } from 'widget/quizQuestion/QuizQuestion';
import { Navback } from 'feature/navback';

export const Quiz = () => {
  return (
    <PageWrapper>
      <Header>
        <Navback />
        <Balance />
        <div style={{ width: '48px' }} />
      </Header>
      <PageContent>
        <QuizQuestion />
      </PageContent>
      <Menu />
    </PageWrapper>
  );
};
