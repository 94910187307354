import styled from 'styled-components';
import { colors } from 'app/colors';
import { Button } from 'shared/button';
import { TimerDisplay, TimerUnit } from 'shared/timer/Timer.styled';

export const QuizWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
`;

export const QuizHeader = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const QuizContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 0 118px 0;
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow:
      inset 0 125px 60px -60px rgba(0, 0, 0, 1),
      inset 0 -125px 60px -60px rgba(0, 0, 0, 1);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const StepCounter = styled.div`
  color: ${colors.whiteColor};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 16px;
  text-align: center;
  margin-bottom: 0;
  z-index: 1;
`;

export const QuizTitle = styled.h2`
  color: ${colors.secondaryColor};
  font-size: 16px;
  text-align: center;
  margin: 24px 0;
  max-width: 270px;
  z-index: 1;
`;

export const QuizGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  padding: 0 16px;
  width: 100%;
  max-width: 600px;
`;

export const ColorGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 36px);
  gap: 16px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px;
  margin: 0 auto;
`;

export const ColorCircle = styled.button<{
  color: string;
  isSelected: boolean;
}>`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: ${({ isSelected, color }) =>
    isSelected ? 'transparent' : color};
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid #ffffff;
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.7)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButton = styled(Button)<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background: ${colors.notIntensiveColor};
  color: ${colors.whiteColor};
  font-size: 12px;
  cursor: pointer;
  transition: all 0.1s;
  border: 1px solid
    ${({ isSelected }) =>
      isSelected ? colors.secondaryColor : colors.notIntensiveColor};
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.7)};
`;

export const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 160px;
  height: 56px;
`;

export const StyledSubmitButton = styled(Button)`
  width: 100%;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  transition: opacity 0.2s ease;

  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const TimeBlock = styled.div`
  width: 100%;
  text-align: center;
  gap: 5px;
  padding: 16px;
  color: ${colors.whiteColor} !important;
  opacity: 0.5;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1.5px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  ${TimerDisplay}, ${TimerUnit} {
    color: ${colors.whiteColor} !important;
    font-size: 10px !important;
    display: inline !important;
    gap: 0 !important;
    letter-spacing: 1.5px !important;
  }
`;
