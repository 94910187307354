import {
  Wrapper,
  LargeText,
  SmallText,
  MediumText,
  TimerContainer,
  TitleContainer,
  PurchaseContainer,
  BoostTimesContainer,
} from './BoostItem.styled';
import { useMemo } from 'react';
import { colors } from 'app/colors';
import { Timer } from 'shared/timer';
import { Button } from 'shared/button';
import { useModal } from 'shared/modal';
import { useToast } from 'shared/toast';
import { Gradient } from 'shared/gradient';
import { LogoIcon } from 'shared/icon/LogoIcon';
import { CrossIcon } from 'shared/icon/CrossIcon';
import { BoostItemProps } from './boostItem.types';
import { useBuyBoostMutation } from '../model/api';
import { EnergyIcon } from 'shared/icon/EnergyIcon';
import { BoostModal } from 'widget/Boosts/BoostModal';
import { BoostNameContainer } from 'shared/boostNameContainer';
import { StyledButtonIcon } from 'entities/tasks/Tasks.styled';
import { StyledBorderIcon } from 'shared/button/Button.styled';
import { formatNumber, getNextDayStartUTC } from 'shared/utils';
import { DivDecoration } from 'shared/icon/decoration/DivDecoration';

export const BoostItem = ({ boost, limit }: BoostItemProps) => {
  const { showToast } = useToast();
  const [buyBoost] = useBuyBoostMutation();
  const { setIsOpen, setModalProps } = useModal();

  const boostEndTime = useMemo(() => {
    return new Date(boost.endTime * 1000);
  }, [boost.endTime]);

  const BoostIcon = useMemo(
    () =>
      boost.type === 'tap'
        ? () => <CrossIcon />
        : () => <EnergyIcon color={colors.whiteColor} />,
    [boost.type]
  );

  const hasReachedDailyLimit = boost.activatedTimes >= limit;
  const isActive = boost.status === 'active' && +boostEndTime > Date.now();

  const handleBoostClick = () => {
    const handleClose = () => setIsOpen(false);

    setModalProps({
      onRequestClose: handleClose,
      children: (
        <BoostModal
          type={boost.type}
          cost={boost.price}
          title={boost.name}
          onClose={handleClose}
          btnClickHandler={handleClickBuyBoost}
          description={boost.description || 'Instantly recover full energy'}
        />
      ),
      isOpen: true,
    });
    setIsOpen(true);
  };

  const handleClickBuyBoost = async () => {
    try {
      await buyBoost({
        boostId: boost.id,
        type: 1,
      }).unwrap();

      setIsOpen(false);
      showToast('Boost activated', 'success');
    } catch (error: any) {
      if (
        error &&
        error.data &&
        error.data.cause &&
        error.status === 400 &&
        error.data.cause.message.includes('already')
      ) {
        showToast(error.data.cause.message, 'error');
        console.error('Failed to buy boost: ' + boost.name, error);
        return;
      }

      showToast('Failed to buy boost', 'error');
      console.error('Failed to buy boost: ' + boost.name, error);
    }
  };

  return (
    <Wrapper>
      <BoostNameContainer>
        <TitleContainer>
          <BoostIcon />{' '}
          <LargeText style={{ marginLeft: '10px' }}>{boost.name}</LargeText>
        </TitleContainer>
      </BoostNameContainer>
      <MediumText style={{ marginTop: '10px' }}>
        {boost.description || 'Instantly recover full energy'}
      </MediumText>
      <PurchaseContainer>
        <BoostTimesContainer>
          <StyledBorderIcon
            color={colors.whiteColor}
            decoration={'basic'}
            height={'32px'}
            isFirst
          />
          <BoostIcon />
          <StyledBorderIcon
            color={colors.whiteColor}
            decoration={'basic'}
            height={'32px'}
          />
          <LargeText style={{ marginLeft: '16px' }}>
            {boost.activatedTimes || 0}/3
          </LargeText>
        </BoostTimesContainer>
        <Button
          decoration='basic'
          borderColor={colors.primaryColor}
          size='m'
          disabled={hasReachedDailyLimit || isActive}
          onClick={handleBoostClick}
          style={{
            opacity: hasReachedDailyLimit || isActive ? 0.5 : 1,
          }}
        >
          <Gradient color={colors.primaryColor}>
            BUY {formatNumber(boost.price)}
            <StyledButtonIcon>
              <LogoIcon width={20} height={20} />
            </StyledButtonIcon>
          </Gradient>
        </Button>
      </PurchaseContainer>
      <SmallText>available for purchase {limit} times a day</SmallText>
      {hasReachedDailyLimit && (
        <>
          <SmallText>Daily limit reached. New boosts available in:</SmallText>
          <TimerContainer>
            <DivDecoration height={'56px'} width={11} opacity={0.25} />
            <Timer withDays={false} targetDate={getNextDayStartUTC()} />
            <DivDecoration
              isReverse
              height={'56px'}
              width={11}
              opacity={0.25}
            />
          </TimerContainer>
        </>
      )}
    </Wrapper>
  );
};
