import { PageTitle } from 'shared/typography';
import { Boosts } from 'widget/Boosts/Boosts';
import { Header, PageContent, PageWrapper } from 'shared/layout';
import { Navback } from 'feature/navback';

export const BoostsMarket = () => {
  return (
    <PageWrapper>
      <Header>
        <Navback />
        <PageTitle>Boost Market</PageTitle>
        <div style={{ width: 48 }} />
      </Header>
      <PageContent>
        <Boosts />
      </PageContent>
    </PageWrapper>
  );
};
