// Carousel.tsx
import React, {
  forwardRef,
  useDebugValue,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { CarouselProps } from './carusel.types';
import {
  CarouselContainer,
  GradientLeft,
  GradientRight,
} from './Carusel.styled';
import { useDebounce } from 'react-use';

// Адаптивные настройки по умолчанию
const defaultResponsive = {
  mobile: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
    partialVisibilityGutter: 1000,
  },
};

// Обёртка с опциональным управлением через ref
export const Carousel = forwardRef((props: CarouselProps, ref) => {
  const { enableGradient = false, activeSlide } = props; // Извлекаем prop enableGradient с значением по умолчанию `true`
  const carouselRef = useRef<any>(null);

  useLayoutEffect(() => {
    if (carouselRef.current && activeSlide) {
      carouselRef.current.goToSlide(activeSlide);
    }
  }, [activeSlide]);

  useImperativeHandle(
    ref,
    () => ({
      next: () => carouselRef.current.next(),
      previous: () => carouselRef.current.previous(),
      goToSlide: (index: number) => carouselRef.current.goToSlide(index),
      state: carouselRef.current.state,
    }),
    [carouselRef?.current?.state]
  );

  return (
    <CarouselContainer>
      {enableGradient && (
        <>
          <GradientLeft />
          <GradientRight />
        </>
      )}
      <MultiCarousel
        ref={carouselRef}
        {...props}
        responsive={props.responsive || defaultResponsive} // Используем настройки по умолчанию, если responsive не передан
      />
    </CarouselContainer>
  );
});
