import * as React from 'react';
import { IconProps } from './icon.types';

export const CrossIcon: React.FC<IconProps> = ({
  color = '#fff',
  width,
  height,
  size = 24,
  ...props
}) => (
  <svg
    width={size || width}
    height={size || height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M4.38579 20H7.22336C7.44978 20 7.66658 19.8943 7.81592 19.7132L11.9976 14.5057L16.1793 19.7132C16.3238 19.8943 16.5406 20 16.7718 20H19.6094C19.937 20 20.1152 19.6025 19.9033 19.3409L14.0113 12L19.9081 4.65912C20.1201 4.39748 19.9418 4 19.6142 4H16.7766C16.5502 4 16.3334 4.10566 16.1841 4.28679L11.9976 9.49434L7.81592 4.28679C7.67139 4.10566 7.4546 4 7.22336 4H4.38579C4.0582 4 3.87995 4.39748 4.09192 4.65912L9.98384 12L4.09192 19.3409C4.04444 19.3992 4.01397 19.4705 4.00415 19.5464C3.99432 19.6222 4.00554 19.6995 4.03649 19.7689C4.06743 19.8382 4.11679 19.8969 4.1787 19.9379C4.24062 19.9789 4.3125 20.0004 4.38579 20Z'
      fill={color}
    />
  </svg>
);
