import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BoostsResponse, BuyBoostRequest, BuyBoostResponse } from './types';

export const boostsApi = createApi({
  reducerPath: 'boostsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    getBoosts: builder.mutation<BoostsResponse, void>({
      query: () => ({
        url: '/boosts',
        method: 'GET',
      }),
    }),
    buyBoost: builder.mutation<BuyBoostResponse, BuyBoostRequest>({
      query: (body) => ({
        url: '/boosts/buy-boost',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetBoostsMutation, useBuyBoostMutation } = boostsApi;
