import styled from 'styled-components';
import { colors } from 'app/colors';
import { Text } from 'shared/typography';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const Title = styled(Text)`
  font-size: 20px;
  margin: 24px 0 16px 0;
  color: ${colors.whiteColor};
`;

export const RewardText = styled(Text)`
  font-size: 10px;
  letter-spacing: 2px;
  color: ${colors.whiteColor};
  margin-bottom: 24px;
  span {
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    letter-spacing: 2px;
    color: ${colors.whiteColor};
  }
`;
