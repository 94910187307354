import { colors } from 'app/colors';
import { IconProps } from './icon.types';

export const CheckmarkIcon: React.FC<IconProps> = ({
  color = colors.fourthColor,
  width = 16,
  height = 16,
  border,
  borderWidth = 1.5,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    {border && (
      <path
        d='M20.2652 5.73483C20.4116 5.88128 20.4116 6.11872 20.2652 6.26517L9.26516 17.2652C9.11872 17.4116 8.88128 17.4116 8.73484 17.2652L3.73483 12.2652C3.58839 12.1187 3.58839 11.8813 3.73483 11.7348C3.88128 11.5884 4.11872 11.5884 4.26517 11.7348L9 16.4697L19.7348 5.73483C19.8813 5.58839 20.1187 5.58839 20.2652 5.73483Z'
        stroke={border}
        strokeWidth={borderWidth}
        fill='none'
      />
    )}
    <path
      d='M20.2652 5.73483C20.4116 5.88128 20.4116 6.11872 20.2652 6.26517L9.26516 17.2652C9.11872 17.4116 8.88128 17.4116 8.73484 17.2652L3.73483 12.2652C3.58839 12.1187 3.58839 11.8813 3.73483 11.7348C3.88128 11.5884 4.11872 11.5884 4.26517 11.7348L9 16.4697L19.7348 5.73483C19.8813 5.58839 20.1187 5.58839 20.2652 5.73483Z'
      fill={color}
    />
  </svg>
);
