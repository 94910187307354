import { colors } from 'app/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PurchaseContainer = styled.div`
  width: 100%;
  margin: 10px 0;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TimerContainer = styled.div`
  width: 100%;
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BoostTimesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LargeText = styled.span`
  color: ${colors.whiteColor};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 2px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-transform: uppercase;
`;
export const MediumText = styled.span`
  color: ${colors.whiteColor};
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-transform: uppercase;
`;
export const SmallText = styled.span`
  color: ${colors.whiteColor};
  font-size: 10px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-transform: uppercase;
`;

export const BoostGameProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const GameBoostProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;

  position: fixed;
  top: 50%;
  right: 10px;
  z-index: 99999;
  transform: translate(-50%, -50%);
`;
