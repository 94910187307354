import {
  DoubleSide,
  Material,
  Mesh,
  Object3D,
  OrthographicCamera,
  PerspectiveCamera,
} from 'three';
import {
  modelDefaultPos,
  modelGamePagePos,
  modelGameViewPoints,
  modelProfilePagePos,
  modelStartedGamePos,
  DEFAULT_CAMERA_POSITION,
  GAME_POS_CHANGE_DURATION,
  ROTATION_SPEED,
} from '../configs/3dModelConfigs';
import gsap from 'gsap';
import { Position } from '../types/3dTypes';
import { RootState } from '@react-three/fiber';

interface IMovementModelController {
  sintModel: Object3D;
  hologramModel: Object3D;
  threeState: RootState;
}

export class MovementModelController {
  mintDurationByMillisecond = process.env.REACT_APP_MINT_DURATION || 60000;
  sintModel: Object3D;
  hologramModel: Object3D;
  threeState: RootState;
  camera: PerspectiveCamera | OrthographicCamera;
  lastRandomIndex = 0;
  rotationSpeed = ROTATION_SPEED || 0.01;
  animationID: number | null = null;
  mintingStartDate: Date | null = null;
  mintingEndDate: Date | null = null;
  changeHologramOpacityPeriod: number = 15000;

  constructor(data: IMovementModelController) {
    this.hologramModel = data.hologramModel;
    this.sintModel = data.sintModel;
    this.threeState = data.threeState;
    this.camera = this.threeState.camera;
  }

  /**
   * Move direct model without animation
   */
  private moveObjectDirect = (
    model: Object3D,
    position: Position,
    scale: Position | null = null,
    rotate: Position | null = null,
    isMoveCameraToDefault: boolean = true
  ) => {
    model.position.set(position.x, position.y, position.z);
    scale && model.scale.set(scale.x, scale.y, scale.z);
    rotate
      ? model.rotation.set(rotate.x, rotate.y, rotate.z)
      : model.rotation.set(0, 0, 0);

    if (isMoveCameraToDefault) {
      this.moveObjectDirect(
        this.camera,
        DEFAULT_CAMERA_POSITION,
        null,
        null,
        false
      );
    }
  };

  private moveObjectSmooth = (
    model: Object3D,
    position: Position,
    scale: Position | null = null,
    rotate: Position | null = null,
    isMoveCameraToDefault: boolean = true
  ) => {
    this.animateModelMove(
      model,
      'rotation',
      rotate ? rotate : { x: 0, y: 0, z: 0 }
    );
    this.animateModelMove(model, 'position', position);
    scale && model.scale.set(scale.x, scale.y, scale.z);

    if (isMoveCameraToDefault) {
      this.moveObjectDirect(
        this.camera,
        DEFAULT_CAMERA_POSITION,
        null,
        null,
        false
      );
    }
  };

  private calculateTransparencyForHologram = (
    startDate?: Date,
    targetDate?: Date
  ) => {
    if (!startDate) return 1;
    const now = Date.now();

    if (targetDate) {
      const totalDuration = targetDate.getTime() - startDate.getTime();
      const elapsed = now - startDate.getTime();

      return Math.min(1, Math.max(0.1, elapsed / totalDuration));
    }

    const mintDurationMs = +this.mintDurationByMillisecond;
    const launchDate = new Date(startDate.getTime() + mintDurationMs);
    const totalDuration = launchDate.getTime() - startDate.getTime();
    const elapsed = now - startDate.getTime();

    return Math.min(1, Math.max(0.1, elapsed / totalDuration));
  };

  /**
   * Function for animating targetMovement of model.
   */
  private animateModelMove = (
    model: Object3D,
    targetMovement: 'position' | 'rotation' | 'scale',
    position: Position,
    duration: number = GAME_POS_CHANGE_DURATION
  ) => {
    gsap.to(model[targetMovement], {
      x: position.x,
      y: position.y,
      z: position.z,
      duration: duration,
      ease: 'power3.inOut',
    });
  };

  /**
   * Change model position by random - we will be using it in game
   */
  changeSintPosByRandom = () => {
    let randomIndex;

    // Генеруємо новий індекс, поки він не відрізнятиметься від останнього
    do {
      randomIndex = Math.floor(Math.random() * modelGameViewPoints.length);
    } while (randomIndex === this.lastRandomIndex);

    this.lastRandomIndex = randomIndex; // Оновлюємо останній індекс
    const randomViewPoint = modelGameViewPoints[randomIndex];

    const gsapTimeLine = gsap.timeline({
      defaults: { duration: GAME_POS_CHANGE_DURATION, ease: 'power3.inOut' },
    });

    gsapTimeLine
      .to(this.sintModel.position, {
        x: randomViewPoint.position.x,
        y: randomViewPoint.position.y,
        z: randomViewPoint.position.z,
      })
      .to(
        this.sintModel.rotation,
        {
          x: randomViewPoint.rotation.x,
          y: randomViewPoint.rotation.y,
          z: randomViewPoint.rotation.z,
        },
        0
      );
  };

  moveSintPosToProfilePage = () => {
    this.sintModel.visible = true;
    this.hologramModel.visible = false;
    this.moveObjectDirect(this.sintModel, modelProfilePagePos);
  };

  moveModelsToDefaultPos = () => {
    this.moveObjectDirect(this.sintModel, modelDefaultPos);
    this.moveObjectDirect(this.hologramModel, modelDefaultPos);
    this.stopHologramRotation();
  };

  moveSintToGamePageWithAnim = () => {
    this.sintModel.visible = true;
    this.hologramModel.visible = false;
    this.moveObjectSmooth(this.sintModel, modelGamePagePos);
  };

  moveSintToGamePage = () => {
    this.sintModel.visible = true;
    this.hologramModel.visible = false;
    this.moveObjectDirect(this.sintModel, modelGamePagePos);
  };

  moveSintLaunchPage = () => {
    this.sintModel.visible = true;
    this.hologramModel.visible = false;

    this.moveModelsToDefaultPos();
  };

  moveSintToStartGame = () => {
    this.animateModelMove(
      this.sintModel,
      'position',
      modelStartedGamePos,
      0.75
    );
  };

  moveHologramToMintPage = () => {
    this.sintModel.visible = false;
    this.hologramModel.visible = true;

    this.hologramModel.traverse((child: any) => {
      if (child instanceof Mesh) {
        const material = child.material;
        if (material instanceof Material) {
          material.side = DoubleSide;
          material.opacity = 1;
          material.needsUpdate = true;
        }
      }
    });

    this.moveModelsToDefaultPos();

    this.animateHologramRotation();
  };

  moveHologramWithOpacityToLaunchPage = (
    startDate?: Date,
    targetDate?: Date
  ) => {
    this.sintModel.visible = false;
    this.hologramModel.visible = true;

    const progress = this.calculateTransparencyForHologram(
      startDate,
      targetDate
    );

    this.mintingStartDate = startDate ?? null;
    this.mintingEndDate = targetDate ?? null;

    this.hologramModel.traverse((child: any) => {
      if (child instanceof Mesh) {
        const material = child.material;
        if (material instanceof Material) {
          material.side = DoubleSide;
          material.opacity = progress;
          material.needsUpdate = true;
        }
      }
    });
    this.moveModelsToDefaultPos();

    this.animateHologramRotation();
  };

  // TODO add dynamic calculation of opacity for model here.

  /**
   * Animate the rotation of the hologramModel around itself
   */
  animateHologramRotation = () => {
    let lastUpdateTime = Date.now();

    const rotate = () => {
      this.hologramModel.rotation.y += this.rotationSpeed; // Обертання навколо осі Y

      const currentTime = Date.now();
      if (currentTime - lastUpdateTime >= this.changeHologramOpacityPeriod) {
        lastUpdateTime = currentTime;

        if (this.mintingStartDate && this.mintingEndDate) {
          const progress = this.calculateTransparencyForHologram(
            this.mintingStartDate,
            this.mintingEndDate
          );

          this.hologramModel.traverse((child: any) => {
            if (child instanceof Mesh) {
              const material = child.material;
              if (material instanceof Material) {
                material.side = DoubleSide;
                material.opacity = progress;
                material.needsUpdate = true;
              }
            }
          });
        }
      }

      this.animationID = requestAnimationFrame(rotate);
    };

    if (!this.animationID) {
      rotate();
    }
  };

  /**
   * Stop rotating hologramModel
   */
  stopHologramRotation = () => {
    if (this.animationID !== null) {
      cancelAnimationFrame(this.animationID);
      this.animationID = null;
      this.mintingStartDate = null;
      this.mintingEndDate = null;
    }
  };

  /**
   * Change rotation speed
   * @param speed New rotation speed value
   */
  setRotationSpeed = (speed: number) => {
    this.rotationSpeed = speed;
  };
}
