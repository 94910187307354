import styled from 'styled-components';
import { colors } from 'app/colors';

export const QuizWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
`;

export const QuizHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
`;

export const QuizGrid = styled.ul`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 4px;
  position: relative;
  overflow-y: auto;
  scroll-behavior: smooth;
`;

export const Category = styled.li`
  border: 0.5px solid var(--tertiary-color);
  background-color: ${colors.listItemBG};
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
    transform: scale(1);
  }
`;

export const CategoryButton = styled.a`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 7px 0 0;
  height: 56px;
  gap: 10px;
`;

export const CategoryBtnContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
  text-align: start;
`;

export const CategoryContent = styled.div`
  display: flex;
  margin: 0 12px 0 7px;
  position: relative;
  width: 100%;
  align-items: center;
`;

export const IconWrapper = styled.div`
  margin-right: 13px;
  display: flex;
  align-items: center;
`;

export const IconCheckmark = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 4px 0 0;
`;

export const CategoryName = styled.span`
  color: ${colors.whiteColor};
  font-weight: 400;
  width: 100%;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: start;
`;

export const CheckmarkText = styled.span`
  color: ${colors.secondaryColor};
  letter-spacing: 2px;
  font-size: 14px;
  line-height: 16px;
`;

export const RewardText = styled.span`
  color: ${colors.whiteColor};
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 24px;
  line-height: 92%;
  letter-spacing: 0.32px;
`;

export const CheckMark = styled.div`
  display: flex;
  align-items: center;
  height: 85px;
`;

export const QuizItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;

export const QuizIcon = styled.div`
  width: 64px;
  height: 64px;
  margin-bottom: 8px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const DoneLine = styled.div`
  width: 100%;
  height: 1px;
  background: #d8f34f;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;
