import { colors } from 'app/colors';
import { IconProps } from './icon.types';

export const AiIcon: React.FC<IconProps> = ({
  color = colors.fourthColor,
  width = 20,
  height = 20,
  size = 20,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M23.625 10.8333C23.832 10.8333 24 10.6653 24 10.4583C24 10.2513 23.832 10.0833 23.625 10.0833H20V7.75H23.625C23.832 7.75 24 7.58203 24 7.375C24 7.16797 23.832 7 23.625 7H20C20 5.89545 19.1046 5 18 5H17V1.375C17 1.16797 16.832 1 16.625 1C16.418 1 16.25 1.16797 16.25 1.375V5H13.9167V1.375C13.9167 1.16797 13.7487 1 13.5417 1C13.3347 1 13.1667 1.16797 13.1667 1.375V5H10.8333V1.375C10.8333 1.16797 10.6653 1 10.4583 1C10.2513 1 10.0833 1.16797 10.0833 1.375V5H7.75V1.375C7.75 1.16797 7.58203 1 7.375 1C7.16797 1 7 1.16797 7 1.375V5H6C4.89545 5 4 5.89545 4 7H0.375C0.167969 7 0 7.16797 0 7.375C0 7.58203 0.167969 7.75 0.375 7.75H4V10.0833H0.375C0.167969 10.0833 0 10.2513 0 10.4583C0 10.6653 0.167969 10.8333 0.375 10.8333H4V13.1667H0.375C0.167969 13.1667 0 13.3347 0 13.5417C0 13.7487 0.167969 13.9167 0.375 13.9167H4V16.25H0.375C0.167969 16.25 0 16.418 0 16.625C0 16.832 0.167969 17 0.375 17H4C4 18.1046 4.89545 19 6 19H7V22.625C7 22.832 7.16797 23 7.375 23C7.58203 23 7.75 22.832 7.75 22.625V19H10.0833V22.625C10.0833 22.832 10.2513 23 10.4583 23C10.6653 23 10.8333 22.832 10.8333 22.625V19H13.1667V22.625C13.1667 22.832 13.3347 23 13.5417 23C13.7487 23 13.9167 22.832 13.9167 22.625V19H16.25V22.625C16.25 22.832 16.418 23 16.625 23C16.832 23 17 22.832 17 22.625V19H18C19.1046 19 20 18.1046 20 17H23.625C23.832 17 24 16.832 24 16.625C24 16.418 23.832 16.25 23.625 16.25H20V13.9167H23.625C23.832 13.9167 24 13.7487 24 13.5417C24 13.3347 23.832 13.1667 23.625 13.1667H20V10.8333H23.625ZM19.25 17C19.25 17.6893 18.6893 18.25 18 18.25H6C5.31073 18.25 4.75 17.6893 4.75 17V7C4.75 6.31073 5.31073 5.75 6 5.75H18C18.6893 5.75 19.25 6.31073 19.25 7V17Z'
      fill={color}
      fill-opacity='0.5'
    />
    <path d='M15.108 16V8H16V16H15.108Z' fill='white' fill-opacity='0.5' />
    <path
      d='M7.5 16L9.70116 8H11.6475L13.8486 16H12.9682L12.3889 13.9422H8.95972L8.38047 16H7.5ZM10.3731 8.76301L9.15666 13.1445H12.192L10.9755 8.76301H10.3731Z'
      fill={color}
      fill-opacity='0.5'
    />
  </svg>
);
