import { useState, useEffect } from 'react';

import { colors } from 'app/colors';
import { isMobileOnly } from 'react-device-detect';

export const useTelegram = () => {
  const [user, setUser] = useState(null);
  const [initData, setInitData] = useState(null);
  const [startParams, setStartParams] = useState(undefined);
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    if (typeof window.Telegram !== 'undefined') {
      const tg = window.Telegram.WebApp;
      const userData = tg.initDataUnsafe;

      if (Object.keys(userData).length !== 0) {
        tg.expand();
        tg.setBackgroundColor(colors.blackColor);
        tg.disableVerticalSwipes();
        setUser(userData);
        setInitData(tg.initData);
        setStartParams(userData.start_param);
        tg.ready();
        setReady(true);

        try {
          if (isMobileOnly) {
            tg.requestFullscreen();
            tg.lockOrientation();
          }
        } catch (error) {
          console.error('Fullscreen or orientation lock failed:', error);
        }

        // Check haptic feedback support
      } else {
        console.warn('User data not available');
        setReady(false);
      }
    } else {
      console.error('Telegram Web App is not available');
      setReady(false);
    }

    return () => {
      setUser(null);
      setInitData(null);
      setStartParams(undefined);
      setReady(false);
    };
  }, []);

  // Haptic feedback methods
  const triggerImpact = (style = 'light') => {
    if (window.Telegram?.WebApp?.HapticFeedback?.impactOccurred) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred(style);
    } else {
      console.warn('Impact haptic feedback is not available');
    }
  };

  const triggerNotification = (type = 'success') => {
    if (window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred) {
      window.Telegram.WebApp.HapticFeedback.notificationOccurred(type);
    } else {
      console.warn('Notification haptic feedback is not available');
    }
  };

  const triggerSelection = () => {
    if (window.Telegram?.WebApp?.HapticFeedback?.selectionChanged) {
      window.Telegram.WebApp.HapticFeedback.selectionChanged();
    } else {
      console.warn('Selection haptic feedback is not available');
    }
  };

  return {
    user,
    initData,
    isReady,
    startParams,
    triggerImpact,
    triggerNotification,
    triggerSelection,
  };
};
