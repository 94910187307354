import { Header, PageContent, PageWrapper } from 'shared/layout';
import { PageTitle } from 'shared/typography/Typography.styled';
import { Menu } from 'widget/menu';
import { QuizList } from 'widget/quizList';

export const QuizListPage = () => {
  return (
    <PageWrapper>
      <Header>
        <div />
        <PageTitle>Ai DATA MINING</PageTitle>
        <div />
      </Header>
      <PageContent>
        <QuizList />
      </PageContent>
      <Menu />
    </PageWrapper>
  );
};
