import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SelectQuizResponse } from './types';
import { Quiz } from 'widget/quizList/types';

export const quizApi = createApi({
  reducerPath: 'quizApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    getQuiz: builder.mutation<Quiz[], void>({
      query: () => ({
        url: '/quiz',
        method: 'GET',
      }),
    }),
    selectQuiz: builder.mutation<SelectQuizResponse, number>({
      query: (quizId) => ({
        url: `/quiz/${quizId}`,
        method: 'POST',
      }),
    }),
    continueQuiz: builder.mutation<SelectQuizResponse, number>({
      query: (quizId) => ({
        url: `/quiz/${quizId}`,
        method: 'GET',
      }),
    }),
    submitAnswer: builder.mutation<
      SelectQuizResponse,
      {
        quizId?: number;
        questionId: number;
        answerId: number | number[];
      }
    >({
      query: ({ quizId, questionId, answerId }) => ({
        url: `/quiz/${quizId}`,
        method: 'PATCH',
        body: { questionId, answerId },
      }),
    }),
  }),
});

export const {
  useGetQuizMutation,
  useSelectQuizMutation,
  useContinueQuizMutation,
  useSubmitAnswerMutation,
} = quizApi;
