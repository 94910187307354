import { Loader } from 'shared/loader';
import { Wrapper } from './Boosts.styled';
import { useSelector } from 'react-redux';
import { useLayoutEffect, useMemo } from 'react';
import { BoostItem, selectBoosts } from 'entities/boosts';
import { useGetBoostsMutation } from 'entities/boosts/model/api';
import {
  selectActivatedBoosts,
  selectMaxActivationLimit,
} from 'entities/boosts/model/slice';

export const Boosts = () => {
  const [getBoosts, { isLoading }] = useGetBoostsMutation();
  const boostsList = useSelector(selectBoosts);
  const activatedBoostsList = useSelector(selectActivatedBoosts);
  const activateLimit = useSelector(selectMaxActivationLimit);

  const resultBoosts = useMemo(() => {
    return boostsList.map((el) => {
      const active = activatedBoostsList.find(
        (boost) => boost.boostId === el.id
      );

      return {
        ...el,
        endTime: active?.endTime ?? 0,
        status: active?.status ?? 'inactive',
        activatedTimes: active?.activatedTimes ?? 0,
      };
    });
  }, [boostsList, activatedBoostsList]);

  useLayoutEffect(() => {
    getBoosts();
  }, []);

  if (isLoading) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {resultBoosts.map((boost) => (
        <BoostItem key={boost.id} boost={boost} limit={activateLimit} />
      ))}
    </Wrapper>
  );
};
