import { StyledContainerBase } from './boostNameContainer.styled';
import { FC, ReactNode } from 'react';

interface IStyledContainerProps {
  children: ReactNode;
  bgColor?: string;
  direction?: 'left' | 'right';
}

const polygonDeriction = {
  left: 'polygon(0 0, 0 36%, 0 51%, 0 80%, 5% 100%, 51% 100%, 100% 100%, 100% 51%, 98% 51%, 98% 25%, 100% 25%, 100% 0)',
  right: `polygon(0 20%, 1% 20%, 1% 55%, 0 55%, 0 100%, 51% 100%, 97% 100%, 100% 70%, 100% 49%, 100% 20%, 100% 0, 0 0)`,
};

export const BoostNameContainer: FC<IStyledContainerProps> = ({
  children,
  direction = 'right',
  bgColor = 'rgba(127, 100, 242, 0.25)',
}) => {
  return (
    <StyledContainerBase
      style={{
        background: bgColor,
        clipPath: polygonDeriction[direction],
      }}
    >
      {children}
    </StyledContainerBase>
  );
};
