import { FC, useMemo } from 'react';
import { colors } from 'app/colors';
import { Boost } from '../model/types';
import { EnergyIcon } from 'shared/icon';
import { CrossIcon } from 'shared/icon/CrossIcon';
import { StyledBorderIcon } from 'shared/button/Button.styled';
import {
  BoostGameProgressWrapper,
  BoostTimesContainer,
  SmallText,
} from './BoostItem.styled';

interface IProps {
  boost: Boost;
}

export const BoostProgress: FC<IProps> = ({ boost }) => {
  const BoostIcon = useMemo(
    () =>
      boost.type === 'tap'
        ? () => <CrossIcon size={16} />
        : () => <EnergyIcon size={16} color={colors.whiteColor} />,
    [boost.type]
  );

  return (
    <BoostGameProgressWrapper>
      <BoostTimesContainer>
        <StyledBorderIcon
          color={colors.whiteColor}
          decoration={'basic'}
          height={'24px'}
          isFirst
        />
        <BoostIcon />
        <StyledBorderIcon
          color={colors.whiteColor}
          decoration={'basic'}
          height={'24px'}
        />
      </BoostTimesContainer>
      <SmallText>{boost.activatedTimes || 0}/3</SmallText>
    </BoostGameProgressWrapper>
  );
};
