// src/app/store.ts
import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './rootReducer';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { tasksApi } from 'entities/tasks/api';
import { userApi } from 'entities/user';
import { boostsApi } from 'entities/boosts';
import { quizApi } from 'entities/quiz';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(tasksApi.middleware, boostsApi.middleware, quizApi.middleware),
});

// Типы для корневого состояния и диспетчера
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Кастомные хуки для использования в компонентах
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
