import { useAppSelector } from 'app/store/rootStore';
import { selectRefCount, selectRefInfo } from '../model/slice';

export const ReferralsCount = () => {
  const refInfo = useAppSelector(selectRefInfo);
  const refCount = useAppSelector(selectRefCount);

  return (
    <> {refInfo?.maxNumberOfAccounts ? refInfo?.invitedCount : refCount}</>
  );
};
