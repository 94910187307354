// tabs.styled.ts
import styled from 'styled-components';
import { TabButtonProps } from './tabs.types';

export const TabContainer = styled.div`
  height: 100%;
`;

export const TabNavigation = styled.div`
  display: flex;
  gap: 6px;
  margin-bottom: 20px;
  justify-content: center;
`;

export const TabButton = styled.button<TabButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 40px;
  width: 100%;
  color: #ffffff;
  position: relative;
  font-weight: 400;
  font-size: 12px;
  line-height: 133%;
  letter-spacing: 1.28px;
  text-transform: uppercase;
  svg {
    display: flex;
    position: absolute;
    top: 0;
    left: auto;
  }
  span {
    position: relative;
    z-index: 10;
  }
`;

export const TabContent = styled.div`
  color: var(--white-color);
  overflow-x: hidden;
  height: calc(100% - 80px);
`;

export const AdditionalTabContent = styled.div`
  color: var(--white-color);
  overflow-x: hidden;
  height: auto;
`;

export const TabSegment = styled.div`
  height: 100%;
  color: #fff;
  overflow: scroll;
`;
