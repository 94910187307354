import { CoinIcon } from 'shared/icon';
import React, { FC, memo } from 'react';
import { CentredWrapper } from 'shared/layout';
import { CircleBg } from 'feature/game/balance';
import {
  Balance,
  IconWrapper,
  Name,
  UserRatingPos,
  UserRatingPositionWrapper,
} from './LeaderBoard.styled';
import { formatNumber } from 'shared/utils';

interface IProps {
  position: number;
  userID: number;
  amount: number;
  title: string;
}
export const UserRatingPosition: FC<IProps> = memo(
  ({ position, userID, amount, title }) => {
    return (
      <UserRatingPositionWrapper>
        <UserRatingPos>{position}</UserRatingPos>
        <Name>{title || 'user_' + userID}</Name>
        <Balance>
          {formatNumber(amount) || 0}
          <IconWrapper>
            <CentredWrapper>
              {' '}
              <CircleBg>
                <CoinIcon />
              </CircleBg>
            </CentredWrapper>
          </IconWrapper>
        </Balance>
      </UserRatingPositionWrapper>
    );
  }
);
