import { colors } from 'app/colors';
import { FC, useState } from 'react';
import { Button } from 'shared/button';
import { useToast } from 'shared/toast';
import { Text } from 'shared/typography';
import { Gradient } from 'shared/gradient';
import { BonusContainer } from 'shared/layout';
import { LogoIcon } from 'shared/icon/LogoIcon';
import { CrossIcon } from 'shared/icon/CrossIcon';
import { EnergyIcon } from 'shared/icon/EnergyIcon';
import { useAppSelector } from 'app/store/rootStore';
import { selectBalance } from 'entities/user/model/slice';
import { StyledButtonIcon } from 'entities/tasks/Tasks.styled';
import { DivDecoration } from 'shared/icon/decoration/DivDecoration';
import { Wrapper, TextBlock, IconWrapper, Cost } from './BoostModal.styled';

interface BoostModalProps {
  onClose: () => void;
  cost: number;
  title: string;
  description: string;
  type: 'energy' | 'tap';
  btnClickHandler: () => Promise<any>;
}

export const BoostModal: FC<BoostModalProps> = ({
  onClose,
  cost,
  type,
  title,
  description,
  btnClickHandler,
}) => {
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const balance = useAppSelector(selectBalance);
  const BoostIcon = type === 'tap' ? CrossIcon : EnergyIcon;
  const hasEnoughBalance = balance >= cost;

  const handleBuy = async () => {
    setIsLoading(true);

    if (!hasEnoughBalance) {
      showToast('Insufficient balance', 'warn');
      setIsLoading(false);
      onClose();
      return;
    }

    await btnClickHandler();
    setIsLoading(false);
  };

  return (
    <Wrapper>
      <IconWrapper>
        <DivDecoration width={8} height={'72px'} />
        <BonusContainer width='80px' height='80px'>
          <BoostIcon size={50} color={colors.secondaryColor} />
        </BonusContainer>
        <DivDecoration width={8} isReverse height={'72px'} />
      </IconWrapper>

      <TextBlock>
        <Text style={{ fontSize: 20 }}>{title}</Text>
        <Text
          style={{
            color: colors.fourthColor,
            fontSize: 14,
            marginTop: 10,
            width: '70%',
          }}
        >
          {description}
        </Text>
        <Text
          style={{
            color: colors.whiteColor,
            fontSize: 10,
            marginTop: 16,
          }}
        >
          BOOST COST
        </Text>
        <Cost
          style={{
            color: colors.whiteColor,
          }}
        >
          {cost}
          <StyledButtonIcon>
            <LogoIcon width={20} height={20} />
          </StyledButtonIcon>
        </Cost>
      </TextBlock>

      <Button
        onClick={handleBuy}
        decoration='basic'
        borderColor={colors.primaryColor}
        size='l'
        disabled={isLoading || !hasEnoughBalance}
      >
        <Gradient color={colors.primaryColor}>
          <Text>
            {isLoading
              ? 'BUYING...'
              : !hasEnoughBalance
                ? 'INSUFFICIENT BALANCE'
                : 'BUY'}
          </Text>
        </Gradient>
      </Button>
    </Wrapper>
  );
};
