import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from 'react';
import {
  BUTTON_SIZE,
  generateData,
  GeneratedElement,
  SCENE_SIZE,
  STAGES,
  TIME_DECREMENT,
  TOTAL_SEQUENCES,
  transformSequencesToSteps,
} from '../utils/generator';
import { Button, ButtonSize } from 'shared/button';
import { Gradient } from 'shared/gradient';
import { Carousel } from 'shared/carousel';
import { CentredWrapper } from 'shared/layout';
import { colors } from 'app/colors';
import { GradientTimer } from 'shared/gradientTimer';
import { StepDirection, Steps } from 'shared/steps';
import {
  BubbleArea,
  ExitWrapper,
  GameButton,
  GameWrapper,
  InfoWrapper,
  RobotWrapper,
  Scene3dContainerGamePage,
  StatsWrapper,
} from '../Game.styled';
import { Energy } from 'feature/game/energy';
import { useAppDispatch, useAppSelector } from 'app/store/rootStore';
import {
  selectBalance,
  selectEnergy,
  selectTapValue,
  updateBalance,
  updateEnergy,
} from 'entities/user';
import { Text } from 'shared/typography';
import useSound from 'use-sound';
import { use3dModelContext } from '../../3d';
import { StyledText } from 'widget/fakeMint/FakeMint.styled';
import { Counter } from 'shared/counter';
import { setIsShowMenu, setShowStars } from 'entities/settings';
import { CloseIcon } from 'shared/icon';
import { useModal } from 'shared/modal';
import { OutOfEnergy } from './OutOfEnergy';
import { CharacterStats } from 'widget/characterStats';
import {
  useStartSessionMutation,
  useUpdateBalanceMutation,
} from 'entities/user/model/api';
import winWav from 'assets/sound/winSound.wav';
import losekWav from 'assets/sound/loseSound.wav';
import { useToast } from 'shared/toast';
import { ImpactStyle, useTelegram } from 'feature/auth';
import { BoostBtn } from '../boost';
import { useSelector } from 'react-redux';
import {
  selectActivatedBoostsForGame,
  selectActivatedEnergyByDay,
} from 'entities/boosts/model/slice';
import { BoostPanelInGame } from 'entities/boosts/ui/BoostPanelInGame';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'app';
import { BackIcon } from 'shared/icon/back/BackIcon';

const MemoizedGameButton = React.memo(GameButton);
const MemoizedGradientTimer = React.memo(GradientTimer);
const MemoizedSteps = React.memo(Steps);

export const MainGame: React.FC = () => {
  const energy = useAppSelector(selectEnergy);
  const walletBalance = useAppSelector(selectBalance);
  const [gapWalletbalance, setGapWalletBalance] = useState(0);

  const [isrtl, setRtl] = useState(false);
  const dispatch = useAppDispatch();
  const { setIsOpen, setModalProps } = useModal();
  const [winSound] = useSound(winWav);
  const [loseSound] = useSound(losekWav);

  const {
    mount3dScene,
    unmount3dScene,
    movementControllerOfModel,
    setRotationX,
  } = use3dModelContext();
  const [syncParams] = useUpdateBalanceMutation();
  const [startSession, { isLoading, isError, isSuccess }] =
    useStartSessionMutation();
  const [gameStarted, setGameStarted] = useState(false);
  const [clicked, setClicked] = useState<any>([]);
  const [stage, setStage] = useState<number>(1);
  const { triggerImpact } = useTelegram();
  const [correctSequencesCount, setCorrectSequencesCount] = useState(0);
  const [incorrectSequencesCount, setincorrectSequencesCount] = useState(0);
  const activeBoosts = useSelector(selectActivatedBoostsForGame);
  const tapValue = activeBoosts[0]?.multiplier || 1;
  const { showToast } = useToast();

  const navigate = useNavigate();
  const activeEnergyBoost = useSelector(selectActivatedEnergyByDay);

  const [correctSequencesBtwnSync, setCorrectSequencesBtwnSync] = useState(0);
  const [incorrectSequencesBtwnSync, setIncorrectSequencesBtwnSync] =
    useState(0);

  const [primary, setPrimary] = useState<GeneratedElement[][]>([]);
  const [final, setFinal] = useState<GeneratedElement[][]>([]);
  const [fake, setFake] = useState<GeneratedElement[][]>([]);
  const [currentSequenceIndex, setCurrentSequenceIndex] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);

  const [sequenceCompleted, setSequenceCompleted] = useState(false);
  const [sequenceFailed, setSequenceFailed] = useState(false);

  const [streak, setStreak] = useState(0);
  const [multiplier, setMultiplier] = useState(1);
  const [stepMulti, setStepMulti] = useState<any>([]);

  const [timer, setTimer] = useState(STAGES[1].gameTime);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const scene3dContainerGamePage = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const carouselRef = useRef<any>(null);

  const [randomPositions, setRandomPositions] = useState<
    { top: number; left: number; size: ButtonSize }[]
  >([]);

  const lowEnergyToastShownRef = useRef(false);

  const apiSync = (isClear?: boolean) => {
    console.log({
      amount: gapWalletbalance,
      energy: energy,
      timestamp: new Date().getTime(),
      correctSequences: correctSequencesCount,
      incorrectSequences: incorrectSequencesCount,
      streaks: stepMulti,
      stage: stage,
    });

    if (isClear) {
      syncParams({
        amount: gapWalletbalance,
        energy: energy,
        timestamp: new Date().getTime(),
        correctSequences: 0,
        incorrectSequences: 0,
        streaks: [],
        stage: 1,
      });
    } else {
      syncParams({
        amount: gapWalletbalance,
        energy: energy,
        timestamp: new Date().getTime(),
        correctSequences: correctSequencesBtwnSync,
        incorrectSequences: incorrectSequencesBtwnSync,
        streaks: stepMulti,
        stage: stage,
      });
    }
    setGapWalletBalance(0);
    setIncorrectSequencesBtwnSync(0);
    setStepMulti([]);
    setCorrectSequencesBtwnSync(0);
  };

  const handleOutOfEnergy = () => {
    setIsOpen(false);
    if (gameStarted) {
      closeGame();
    }

    if (
      (activeEnergyBoost && activeEnergyBoost.activatedTimes < 3) ||
      !activeEnergyBoost
    ) {
      navigate(ROUTES.BOOSTS);
    }
  };

  const generate = useCallback(
    (stage: number) => {
      if (energy - 1 < 1) {
        setModalProps({
          onRequestClose: handleOutOfEnergy,
          children: <OutOfEnergy stage={stage} onNext={handleOutOfEnergy} />,
        });
        setIsOpen(true);
        return;
      }
      const generatedFinal: GeneratedElement[][] = final;
      const generatedPrimary: GeneratedElement[][] = primary;
      const generatedFake: GeneratedElement[][] = fake;

      for (let i = 0; i < TOTAL_SEQUENCES; i++) {
        const { primaryArray, finalArray, fakeElements } = generateData(
          STAGES[stage].minPrimary,
          STAGES[stage].maxPrimary,
          STAGES[stage].minFake,
          STAGES[stage].maxFake
        );

        generatedFinal.push(finalArray);
        generatedPrimary.push(primaryArray);
        generatedFake.push(fakeElements);
      }
      // console.log('final:', final.length);
      // console.log('currentSequenceIndex:', currentSequenceIndex);
      // console.log('generatedPrimaryA:', generatedPrimary);
      if (final.length > TOTAL_SEQUENCES + 3) {
        const excessLength = final.length - (TOTAL_SEQUENCES + 2);

        // Удаляем лишние элементы
        const removedFinal = generatedFinal.splice(0, excessLength);
        const removedPrimary = generatedPrimary.splice(0, excessLength);
        const removedFake = generatedFake.splice(0, excessLength);

        setCurrentSequenceIndex(currentSequenceIndex + 1 - excessLength);
        setRtl(true);

        // Логируем количество удаленных индексов
        console.log('Removed elements count:', excessLength);
        console.log('Removed from final:', removedFinal);
        console.log('Removed from primary:', removedPrimary);
        console.log('Removed from fake:', removedFake);
      } else {
        setRtl(false);
      }

      // generatedPrimary
      // generatedFake
      setTimer(STAGES[stage].gameTime);
      // console.log('generatedPrimaryB:', generatedPrimary);
      setFinal(generatedFinal);
      setPrimary(generatedPrimary);
      setFake(generatedFake);
      setGameStarted(true);
    },
    [
      final.length,
      walletBalance,
      stage,
      energy,
      gameStarted,
      currentSequenceIndex,
    ]
  );

  useEffect(() => {
    if (streak > 0 && streak % 10 === 0 && streak / 10 < 10) {
      setMultiplier(streak + 1 < 11 ? streak + 1 : multiplier);
    } else if (streak === 0) {
      setMultiplier(1);
    }
  }, [streak]);

  useLayoutEffect(() => {
    if (isError) {
      showToast('GAME START FAILED', 'error');
      // generate(stage);
    }
  }, [isError]);

  useLayoutEffect(() => {
    if (isSuccess) {
      generate(stage);
    }
  }, [isSuccess]);

  const handleClick = useCallback(
    async (step: number) => {
      try {
        const currentSequence = primary[currentSequenceIndex];
        setClicked((prev: boolean[]) => [...prev, true]);
        dispatch(updateEnergy(energy - 1));
        if (step === currentSequence[currentStep].step) {
          const isLastStep = currentStep + 1 === currentSequence.length;
          if (isLastStep) {
            triggerImpact(ImpactStyle.MEDIUM);
            // winSound();
            completeSequence();
          } else {
            triggerImpact(ImpactStyle.SOFT);

            setCurrentStep((prevStep) => prevStep + 1);
          }
        } else {
          triggerImpact(ImpactStyle.HEAVY);
          // loseSound();
          failSequence();
        }
      } catch (error) {}
    },
    [
      sequenceCompleted,
      primary.length,
      sequenceFailed,
      stage,
      currentSequenceIndex,
      currentStep,
      energy,
      dispatch,
    ]
  );

  useLayoutEffect(() => {
    if (gameStarted && final[currentSequenceIndex]) {
      generateRandomPositions(final[currentSequenceIndex]);
    }
  }, [currentSequenceIndex, gameStarted, primary]);

  useEffect(() => {
    setRotationX(false);
    return () => {
      setRotationX(true);
      closeGame(true);
    };
  }, []);

  useEffect(() => {
    if (
      correctSequencesCount > 0 &&
      correctSequencesCount % 10 === 0 &&
      correctSequencesCount < 100
    ) {
      setStage(correctSequencesCount / 10 + 1);
      dispatch(setShowStars(true));
    }
    if (correctSequencesCount % 2 === 0 && correctSequencesCount > 0) {
      apiSync();
    }
  }, [correctSequencesCount]);

  useEffect(() => {
    carouselRef?.current?.goToSlide(currentSequenceIndex);
  }, [currentSequenceIndex]);

  const completeSequence = useCallback(() => {
    dispatch(setShowStars(false));

    setSequenceCompleted(true);

    const nextSequenceIndex = currentSequenceIndex + 1;

    if (nextSequenceIndex === primary.length) {
      setCorrectSequencesCount((prev) => prev + 1);
      setCorrectSequencesBtwnSync((prev) => prev + 1);
      const correctStage = stage + 1 < 11 ? stage + 1 : stage;
      const stageCalc = correctSequencesCount % 10 === 9 ? correctStage : stage;

      setCurrentSequenceIndex(nextSequenceIndex);
      generate(stageCalc);
      setCurrentStep(0);
      setSequenceCompleted(false);

      setStreak((prevStreak) => prevStreak + 1);
      setMultiplier((prevStreak) =>
        prevStreak + 1 < 11 ? prevStreak + 1 : prevStreak
      );

      if (stepMulti.length < 10) {
        setStepMulti([...stepMulti, multiplier]);
      } else {
        setStepMulti([multiplier]);
      }
      dispatch(
        updateBalance(
          walletBalance + STAGES[stage].reward * tapValue * multiplier
        )
      );
      setGapWalletBalance(
        gapWalletbalance + STAGES[stage].reward * tapValue * multiplier
      );
      setClicked([]);
      resetTimer(STAGES[stageCalc].gameTime);
      movementControllerOfModel?.changeSintPosByRandom();
    }
  }, [
    currentSequenceIndex,
    correctSequencesCount,
    walletBalance,
    gapWalletbalance,
    stage,
    stepMulti,
    primary.length,
    generate,
    multiplier,
  ]);

  const failSequence = useCallback(() => {
    clearInterval(timerRef.current!);
    setSequenceFailed(true);
    const nextSequenceIndex = currentSequenceIndex + 1;
    if (nextSequenceIndex === primary.length) {
      setCurrentSequenceIndex(nextSequenceIndex);
      generate(stage);
      setCurrentStep(0);
      setSequenceFailed(false);
      setincorrectSequencesCount((prev) => prev + 1);
      setIncorrectSequencesBtwnSync((prev) => prev + 1);
      setStreak(0);
      setMultiplier(1);
      setClicked([]);
      resetTimer(STAGES[stage].gameTime);
    }
  }, [currentSequenceIndex, primary.length, generate, stage]);

  useEffect(() => {
    if (timer < TIME_DECREMENT) {
      failSequence();
    }
    if (
      carouselRef &&
      carouselRef.current?.state &&
      carouselRef.current.state.currentSlide !== currentSequenceIndex
    ) {
      carouselRef.current.goToSlide(currentSequenceIndex);
    }
  }, [timer]);

  useEffect(() => {
    if (gameStarted) {
      movementControllerOfModel?.moveSintToStartGame();
      dispatch(setIsShowMenu(false));
      resetTimer(STAGES[stage].gameTime);
    }

    return () => {
      dispatch(setIsShowMenu(true));
      clearInterval(timerRef.current!);
    };
  }, [gameStarted]);

  const resetTimer = (time: number) => {
    clearInterval(timerRef.current!);
    setTimer(time);
    timerRef.current = setInterval(() => {
      setTimer((prev) => {
        return prev - 1000;
      });
    }, 1000);
  };

  const closeGame = (withoutUpdateBalance = false) => {
    movementControllerOfModel?.moveSintToGamePageWithAnim();
    setFinal([]);
    setFake([]);
    setPrimary([]);
    setClicked([]);
    setCurrentSequenceIndex(0);
    setGameStarted(false);
    setStepMulti([]);
    setCurrentStep(0);
    setStreak(0);
    setMultiplier(1);
    setStage(1);
    setCorrectSequencesCount(0);
    setTimer(STAGES[1].gameTime);
    !withoutUpdateBalance && apiSync(true);
  };

  const generateRandomPositions = useCallback(
    (elements: GeneratedElement[]) => {
      if (!containerRef.current) return;

      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight;

      const sizes = STAGES[stage].bubbleSize;
      const positions: { top: number; left: number; size: ButtonSize }[] = [];

      while (positions.length < elements.length) {
        const top = Math.random() * (containerHeight - BUTTON_SIZE);
        const left = Math.random() * (containerWidth - BUTTON_SIZE);
        const isOverlapping = positions.some(
          (pos) =>
            Math.abs(pos.top - top) < BUTTON_SIZE &&
            Math.abs(pos.left - left) < BUTTON_SIZE
        );

        if (!isOverlapping) {
          const size: any = sizes[Math.floor(Math.random() * sizes.length)];
          positions.push({ top, left, size });
        }
      }
      setRandomPositions(positions);
    },
    [currentSequenceIndex]
  );
  const currentBubble = final[currentSequenceIndex] || [];

  useEffect(() => {
    if (scene3dContainerGamePage && scene3dContainerGamePage.current) {
      console.log('SCENE-MOUNT', scene3dContainerGamePage.current.id);
      mount3dScene(scene3dContainerGamePage.current.id);
    }
  }, [scene3dContainerGamePage]);

  useEffect(() => {
    if (
      movementControllerOfModel &&
      scene3dContainerGamePage &&
      scene3dContainerGamePage.current
    ) {
      movementControllerOfModel.moveSintToGamePage();
    }
  }, [movementControllerOfModel]);

  useLayoutEffect(() => {
    return () => {
      console.log('SCENE-UNMOUNT');
      unmount3dScene();
    };
  }, []);

  useEffect(() => {
    lowEnergyToastShownRef.current = false;
  }, [gameStarted]);

  useEffect(() => {
    if (gameStarted && energy < 10 && !lowEnergyToastShownRef.current) {
      const hasActiveEnergyBoost = activeBoosts.some(
        (boost) => boost.type === 'energy' && boost.status === 'active'
      );

      if (!hasActiveEnergyBoost) {
        showToast('Low energy, use boosts!', 'warn');
        lowEnergyToastShownRef.current = true;
      }
    } else if (energy >= 10) {
      lowEnergyToastShownRef.current = false;
    }
  }, [energy, activeBoosts, showToast, gameStarted]);

  return (
    <>
      {!gameStarted && <CharacterStats />}

      <GameWrapper id={'GAME-WRAPPER'}>
        <RobotWrapper>
          <Scene3dContainerGamePage
            id={'3d-scene-game-page'}
            ref={scene3dContainerGamePage}
          />
        </RobotWrapper>
        {!gameStarted ? (
          <CentredWrapper>
            <div
              style={{ width: SCENE_SIZE.width, height: SCENE_SIZE.height }}
            ></div>

            <StyledText>
              <>
                Train Your SYNTHETIC INTELLIGENCE,
                <br />
                Own the Future
              </>
            </StyledText>
            <Button
              onClick={() => {
                startSession('').unwrap();
              }}
              decoration='basic'
              size='l'
              isLoading={isLoading}
              style={{ zIndex: 10 }}
              borderColor={colors.secondaryColor}
            >
              <Gradient color={colors.secondaryColor}>
                <Text style={{ fontSize: 20 }}>Play</Text>
              </Gradient>
            </Button>
          </CentredWrapper>
        ) : (
          <>
            <Carousel
              slidesToSlide={currentSequenceIndex + 1}
              rewind={true}
              ref={carouselRef}
              arrows={false}
              rtl={isrtl}
              draggable={false}
              swipeable={false}
            >
              {primary.map((sequence, seqIndex) => (
                <MemoizedSteps
                  isReverse={isrtl}
                  key={`seq-${sequence[seqIndex]?.value}-${sequence[seqIndex]?.color}-${seqIndex}`}
                  isFailed={sequenceFailed}
                  steps={transformSequencesToSteps(sequence)}
                  isActive={seqIndex === primary.length - 1}
                  currentStep={currentStep}
                  direction={StepDirection.Horizontal}
                  gameTime={STAGES[stage].gameTime}
                />
              ))}
            </Carousel>
            <ExitWrapper>
              <Button
                onClick={() => {
                  closeGame();
                }}
                borderSize={0}
                size='xs'
                borderColor={'#FFFFFF40'}
              >
                <BackIcon />
              </Button>
            </ExitWrapper>
            <BubbleArea ref={containerRef}>
              {currentBubble.map((element, index) => {
                const { top, left, size } = randomPositions[index] || {
                  top: -1000,
                  left: -2000,
                  size: 'm',
                };

                return (
                  <MemoizedGameButton
                    isAnimating={clicked[index]}
                    key={`${index}-${element.step}-${element.value}-${element.color}`}
                    style={{
                      position: 'absolute',
                      top: `${top}px`,
                      left: `${left}px`,
                    }}
                    shape='round'
                    size={size}
                    onClick={() => {
                      handleClick(element.step);
                    }}
                  >
                    <MemoizedGradientTimer
                      color={element.color}
                      isAnim={
                        STAGES[stage].help
                          ? currentStep === element.step
                          : false
                      }
                      borderWidth={0}
                      content={
                        <span style={{ fontSize: 20, fontWeight: 600 }}>
                          {element.value}
                        </span>
                      }
                      isBlum={false}
                      static
                      status='active'
                    />
                  </MemoizedGameButton>
                );
              })}
            </BubbleArea>
            <StatsWrapper>
              <Text
                style={{
                  color: '#FFFFFF80',
                  fontSize: 10,
                  lineHeight: '16px',
                  marginBottom: 8,
                }}
              >
                time
                <Text style={{ fontSize: 14 }}>
                  {STAGES[stage].gameTime / 1000}
                </Text>
              </Text>
              <Text
                style={{
                  color: '#FFFFFF80',
                  fontSize: 10,
                  lineHeight: '16px',
                  marginBottom: 8,
                }}
              >
                STAGE
                <Text style={{ fontSize: 14 }}>
                  <Counter value={stage + '/10'} />
                </Text>
              </Text>
              <Text
                style={{
                  color: '#FFFFFF80',
                  fontSize: 10,
                  lineHeight: '16px',
                  marginBottom: 0,
                }}
              >
                COMBO
                <Text style={{ fontSize: 14 }}>
                  <Counter value={'x' + multiplier} />
                </Text>
              </Text>
              {/* <Text
                style={{
                  color: '#FFFFFF80',
                  fontSize: 10,
                  lineHeight: '16px',
                  marginBottom: 0,
                }}
              >
                Correct Sequences
                <Text style={{ fontSize: 14 }}>
                  <Counter value={correctSequencesCount} />
                </Text>
              </Text>
              <Text
                style={{
                  color: '#FFFFFF80',
                  fontSize: 10,
                  lineHeight: '16px',
                  marginBottom: 0,
                }}
              >
                incorrect Sequences
                <Text style={{ fontSize: 14 }}>
                  <Counter value={incorrectSequencesCount} />
                </Text>
              </Text>
              <Text
                style={{
                  color: '#FFFFFF80',
                  fontSize: 10,
                  lineHeight: '16px',
                  marginBottom: 0,
                }}
              >
                step multi
                <Text style={{ fontSize: 14 }}>
                  <Counter value={stepMulti} />
                </Text>
              </Text>
              <Text
                style={{
                  color: '#FFFFFF80',
                  fontSize: 10,
                  lineHeight: '16px',
                  marginBottom: 0,
                }}
              >
                currentSequenceIndex
                <Text style={{ fontSize: 14 }}>
                  <Counter value={currentSequenceIndex} />
                </Text>
              </Text>
              <Text
                style={{
                  color: '#FFFFFF80',
                  fontSize: 10,
                  lineHeight: '16px',
                  marginBottom: 0,
                }}
              >
                final
                <Text style={{ fontSize: 14 }}>
                  <Counter value={final.length} />
                </Text>
              </Text>
              <Text
                style={{
                  color: '#FFFFFF80',
                  fontSize: 10,
                  lineHeight: '16px',
                  marginBottom: 0,
                }}
              >
                currentStep
                <Text style={{ fontSize: 14 }}>
                  <Counter value={currentStep} />
                </Text>
              </Text>
              <Text
                style={{
                  color: '#FFFFFF80',
                  fontSize: 10,
                  lineHeight: '16px',
                  marginBottom: 0,
                }}
              >
                currentSequenceIndex
                <Text style={{ fontSize: 14 }}>
                  <Counter value={currentSequenceIndex} />
                </Text>
              </Text>

              <Text
                style={{
                  color: '#FFFFFF80',
                  fontSize: 10,
                  lineHeight: '16px',
                  marginBottom: 0,
                }}
              >
                Timer
                <Text style={{ fontSize: 14 }}>
                  <Counter value={timer} />
                </Text>
              </Text> */}
            </StatsWrapper>
            <InfoWrapper>
              {/* <span
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                {streak > 0 && (
                  <StreakAnim value={<Streak>{`${streak}`}</Streak>} />
                )}
              </span> */}
              <Energy />
              <BoostBtn closeGame={closeGame} />
              <BoostPanelInGame />
            </InfoWrapper>
          </>
        )}
      </GameWrapper>
    </>
  );
};
