export enum ROUTES {
  APP = '/app',
  MINT = '/app/mint',
  GAME = '/app/game',
  TASKS = '/app/tasks',
  BOOSTS = '/app/boosts',
  LAUNCH = '/app/launch',
  RATINGS = '/app/ratings',
  PROFILE = '/app/profile',
  SANDBOX = '/app/sandbox',
  LANDING = '/app/landing',
  REFERRAL = '/app/referral',
  WALLET = '/app/connectwallet',
  QUIZ = '/app/quizlist',
  QUIZ_CATEGORY = '/app/quizlist/quiz',

  LOGIN = '/',
  ERROR = '/error',
  VIEWPORT = '/qr',
  WELCOME = '/welcome',
  WAITLIST = '/waitlist',
  WAITLISTSUCCESS = '/waitlistsuccess',
}
