import { FC, useEffect, useState } from 'react';
import { differenceInSeconds } from 'date-fns';
import { TimerProps } from './timer.types';
import {
  TimerContainer,
  TimerDisplay,
  TimerUnit,
  TimerLabel,
} from './Timer.styled';

export const Timer: FC<TimerProps> = ({
  withDays = true,
  targetDate,
  onTimeUp,
  basic,
}) => {
  const initialSeconds = differenceInSeconds(targetDate, new Date());
  const initialTimeLeft = calculateTimeString(initialSeconds);

  const [timeLeft, setTimeLeft] = useState<string>(initialTimeLeft);
  const [secondsLeft, setSecondsLeft] = useState(initialSeconds);

  function calculateTimeString(seconds: number): string {
    if (seconds <= 1) {
      console.log(seconds);
      onTimeUp?.(true);
      return "Time's up!";
    }

    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${days}: ${hours}: ${minutes}: ${secs}`;
  }

  const calculateTimeLeft = () => {
    const seconds = differenceInSeconds(targetDate, new Date());
    setSecondsLeft(seconds);
    setTimeLeft(calculateTimeString(seconds));
  };

  useEffect(() => {
    const interval = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(interval);
  }, [targetDate]);

  const timeParts = timeLeft.split(':').map((part) => part.trim());

  return (
    <TimerContainer>
      {secondsLeft > 0 && (
        <TimerDisplay>
          {basic ? (
            <TimerUnit>
              {timeParts[2]} {Number(timeParts[2]) === 1 ? 'MINUTE' : 'MINUTES'}
            </TimerUnit>
          ) : (
            <>
              {withDays && (
                <TimerUnit>
                  {timeParts[0]}
                  <TimerLabel>d</TimerLabel>:
                </TimerUnit>
              )}
              <TimerUnit>
                {timeParts[1]}
                <TimerLabel>h</TimerLabel>:
              </TimerUnit>
              <TimerUnit>
                {timeParts[2]}
                <TimerLabel>m</TimerLabel>:
              </TimerUnit>
              <TimerUnit>
                {timeParts[3]}
                <TimerLabel>s</TimerLabel>
              </TimerUnit>
            </>
          )}
        </TimerDisplay>
      )}
    </TimerContainer>
  );
};
