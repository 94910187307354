export const millisecondsToString = (milliseconds: number): string => {
  // Константы для преобразования миллисекунд
  const secondsInMillisecond = 1 / 1000;
  const minutesInMillisecond = secondsInMillisecond / 60;
  const hoursInMillisecond = minutesInMillisecond / 60;
  const daysInMillisecond = hoursInMillisecond / 24;
  const monthsInMillisecond = daysInMillisecond / 30; // Приблизительно
  const yearsInMillisecond = monthsInMillisecond / 12;

  // Вычисляем количество лет, месяцев, дней, часов, минут и секунд
  let years = Math.floor(milliseconds * yearsInMillisecond);
  milliseconds -= years / yearsInMillisecond;

  let months = Math.floor(milliseconds * monthsInMillisecond);
  milliseconds -= months / monthsInMillisecond;

  let days = Math.floor(milliseconds * daysInMillisecond);
  milliseconds -= days / daysInMillisecond;

  let hours = Math.floor(milliseconds * hoursInMillisecond);
  milliseconds -= hours / hoursInMillisecond;

  let minutes = Math.floor(milliseconds * minutesInMillisecond);
  milliseconds -= minutes / minutesInMillisecond;

  let seconds = Math.floor(milliseconds * secondsInMillisecond);

  // Формируем строку
  const result: string[] = [];
  if (years > 0) result.push(`${years} year${years > 1 ? 's' : ''}`);
  if (months > 0) result.push(`${months} month${months > 1 ? 's' : ''}`);
  if (days > 0) result.push(`${days} day${days > 1 ? 's' : ''}`);
  if (hours > 0) result.push(`${hours} hour${hours > 1 ? 's' : ''}`);
  if (minutes > 0) result.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
  if (seconds > 0) result.push(`${seconds} second${seconds > 1 ? 's' : ''}`);

  return result.join(' ');
};
