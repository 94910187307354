import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/store/rootStore';
import {
  useGetQuizMutation,
  useSelectQuizMutation,
  useContinueQuizMutation,
} from 'entities/quiz/model/api';
import {
  setActiveQuiz,
  setQuizzes,
  resetQuizState,
  incrementStep,
} from 'entities/quiz';
import { Loader } from 'shared/loader';
import {
  QuizWrapper,
  QuizGrid,
  CategoryButton,
  IconWrapper,
  CategoryName,
  RewardText,
  Category,
  CategoryBtnContainer,
  DoneLine,
  CategoryContent,
  IconCheckmark,
  CheckmarkText,
} from './QuizList.styled';
import { ROUTES } from 'app/router';
import { Button } from 'shared/button';
import { colors } from 'app/colors';
import { Gradient } from 'shared/gradient';
import { StyledButtonIcon } from 'entities/tasks/Tasks.styled';
import { LogoIcon } from 'shared/icon/LogoIcon';
import { CheckmarkIcon } from 'shared/icon/Checkmark';
import { Question } from 'widget/quizQuestion/QuizQuestion.types';
import { useMintingHook } from 'app/hooks';
import { useToast } from 'shared/toast';

const calculatePreviewReward = (questions: Question[]) => {
  return questions.reduce((sum, question) => sum + question.reward, 0);
};

export const QuizList: FC = () => {
  const dispatch = useAppDispatch();
  const [getQuiz, { data: quizzes, isLoading }] = useGetQuizMutation();
  const [selectQuiz] = useSelectQuizMutation();
  const [continueQuiz] = useContinueQuizMutation();

  const { showToast } = useToast();
  const { isSintReady } = useMintingHook();
  const location = useLocation();
  const navigate = useNavigate();

  const quizStatuses = useAppSelector((state) => state.quiz.quizStatuses);
  const userQuizzes = useAppSelector((state) => state.user.account.quizzes);

  useEffect(() => {
    const fetchQuizzes = async () => {
      dispatch(resetQuizState());
      const response = await getQuiz().unwrap();
      if (response) {
        dispatch(setQuizzes(response));
      }
    };

    fetchQuizzes();
  }, [getQuiz, dispatch, location.key]);

  const handleQuizSelect = async (quizId: number) => {
    if (!isSintReady) {
      showToast('Mint SINT first', 'error');
      return;
    }

    try {
      const existingQuiz = userQuizzes.find((q) => q.quizId === quizId);

      if (existingQuiz) {
        const continueResponse = await continueQuiz(existingQuiz.id).unwrap();

        const firstIncompleteIndex = continueResponse.questions.findIndex(
          (q) => q.status !== 'completed'
        );

        if (firstIncompleteIndex !== -1) {
          dispatch(setActiveQuiz(continueResponse));
          for (let i = 0; i < firstIncompleteIndex; i++) {
            dispatch(incrementStep());
          }
        } else {
          console.warn('All questions already completed');
          return;
        }
      } else {
        const response = await selectQuiz(quizId).unwrap();
        dispatch(setActiveQuiz(response));
      }

      navigate(ROUTES.QUIZ_CATEGORY.replace(':id', quizId.toString()));
    } catch (error) {
      const response = await selectQuiz(quizId).unwrap();
      dispatch(setActiveQuiz(response));
    }
  };

  if (isLoading) return <Loader />;

  return (
    <QuizWrapper>
      <QuizGrid>
        {quizzes?.map((quiz) => {
          const totalReward = calculatePreviewReward(
            quiz.questions as Question[]
          );
          const isClaimed = quizStatuses[quiz.id] === 'claimed';

          return (
            <Category key={quiz.id}>
              <CategoryButton
                onClick={() => !isClaimed && handleQuizSelect(quiz.id)}
                style={{
                  cursor: isClaimed ? 'default' : 'pointer',
                  pointerEvents: isClaimed ? 'none' : 'auto',
                }}
              >
                <CategoryBtnContainer>
                  <CategoryContent>
                    {isClaimed && <DoneLine />}
                    <IconWrapper
                      dangerouslySetInnerHTML={{ __html: quiz.icon.svg }}
                      style={{
                        opacity: isClaimed ? 0.5 : 1,
                      }}
                    />
                    <CategoryName
                      style={{
                        opacity: isClaimed ? 0.5 : 1,
                      }}
                    >
                      {quiz.name}
                    </CategoryName>
                  </CategoryContent>
                  <Button
                    decoration='basic'
                    size='m'
                    borderColor={
                      isClaimed ? colors.btnDoneDecoration : colors.primaryColor
                    }
                    style={{
                      borderTop: isClaimed ? 'none' : undefined,
                      borderBottom: isClaimed ? 'none' : undefined,
                    }}
                  >
                    {isClaimed ? (
                      <>
                        <IconCheckmark>
                          <CheckmarkIcon
                            width={16}
                            height={16}
                            color={colors.secondaryColor}
                          />
                        </IconCheckmark>

                        <CheckmarkText>DONE</CheckmarkText>
                      </>
                    ) : (
                      <Gradient color={colors.primaryColor}>
                        <RewardText>
                          {totalReward}
                          <StyledButtonIcon>
                            <LogoIcon width={20} height={20} />
                          </StyledButtonIcon>
                        </RewardText>
                      </Gradient>
                    )}
                  </Button>
                </CategoryBtnContainer>
              </CategoryButton>
            </Category>
          );
        })}
      </QuizGrid>
    </QuizWrapper>
  );
};
