import { FC } from 'react';
import { Button } from 'shared/button';
import { Text } from 'shared/typography';
import { Gradient } from 'shared/gradient';
import { colors } from 'app/colors';
import { CheckMark } from 'widget/launchDate/sintReadyModal.styled';
import { DivDecoration } from 'shared/icon/decoration/DivDecoration';
import { BonusContainer } from 'shared/layout';
import { ModalWrapper, Title, RewardText } from './QuizCompleteModal.styled';
import { CheckmarkIcon } from 'shared/icon/Checkmark';
import { LogoIcon } from 'shared/icon/LogoIcon';

interface Props {
  reward: number;
  onComplete: () => void;
}

export const QuizCompleteModal: FC<Props> = ({ reward, onComplete }) => {
  return (
    <ModalWrapper>
      <CheckMark>
        <DivDecoration width={8} height={'72px'} />
        <BonusContainer width='80px' height='80px'>
          <CheckmarkIcon width={50} height={50} color={colors.secondaryColor} />
        </BonusContainer>
        <DivDecoration width={8} isReverse height={'72px'} />
      </CheckMark>

      <Title>QUEST COMPLETE</Title>
      <RewardText>
        YOU'VE EARNED <br />
        <span>
          {reward} <LogoIcon width={24} height={24} />
        </span>
      </RewardText>
      <Button
        onClick={onComplete}
        borderColor={colors.primaryColor}
        decoration='basic'
        size='l'
      >
        <Gradient color={colors.primaryColor}>
          <Text>OK</Text>
        </Gradient>
      </Button>
    </ModalWrapper>
  );
};
