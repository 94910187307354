import { combineReducers } from '@reduxjs/toolkit';
import { tasksApi } from 'entities/tasks/api';
import tasksReducer from 'entities/tasks/slice';
import { userApi, userReducer } from 'entities/user';
import { boostsApi, boostsReducer } from 'entities/boosts';
import { settingsReducer } from 'entities/settings';
import { quizApi, quizReducer } from 'entities/quiz';

export const rootReducer = combineReducers({
  user: userReducer,
  [userApi.reducerPath]: userApi.reducer,
  tasks: tasksReducer,
  [tasksApi.reducerPath]: tasksApi.reducer,
  boostsSlice: boostsReducer,
  [boostsApi.reducerPath]: boostsApi.reducer,
  settings: settingsReducer,
  quiz: quizReducer,
  [quizApi.reducerPath]: quizApi.reducer,
});
