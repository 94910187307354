import React, {
  useState,
  FC,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import {
  TabContainer,
  TabButton,
  TabContent,
  TabNavigation,
  TabSegment,
  AdditionalTabContent,
} from './tabs.styled';
import { TabsProps } from './tabs.types';
import { TabsButtonDecoration } from 'shared/icon';
import { motion, AnimatePresence } from 'framer-motion';
import { DivDecoration } from 'shared/icon/decoration/DivDecoration';

export const Tabs: FC<TabsProps> = ({
  defaultActiveTab,
  items,
  onTabChange,
  additionalItems,
}) => {
  const [activeTab, setActiveTab] = useState<string>(
    defaultActiveTab || (items.length > 0 ? items[0].title : '')
  );
  const [direction, setDirection] = useState<'left' | 'right'>('right');
  const [isFirstRender, setIsFirstRender] = useState(true);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  const tabHeight = useMemo(() => {
    return additionalItems
      ? !!additionalItems.find((el) => el.title === activeTab)?.segment
        ? 'calc(100% - 60px)'
        : '100%'
      : '100%';
  }, [additionalItems, activeTab]);

  useEffect(() => {
    onTabChange?.(activeTab);
    setIsFirstRender(false);
  }, [activeTab, onTabChange]);

  const handleTabClick = useCallback(
    (title: string) => {
      const targetTab = items.find((item) => item.title === title);
      if (!targetTab?.disable) {
        const currentIndex = items.findIndex(
          (item) => item.title === activeTab
        );
        const newIndex = items.findIndex((item) => item.title === title);

        // Определение направления анимации
        if (newIndex > currentIndex) {
          setDirection('right');
        } else if (newIndex < currentIndex) {
          setDirection('left');
        }
        setActiveTab(title);
      }
    },
    [items, activeTab]
  );

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    touchEndX.current = e.changedTouches[0].clientX;
    handleSwipe();
  };

  const handleSwipe = () => {
    const deltaX = touchEndX.current - touchStartX.current;
    if (Math.abs(deltaX) > 50) {
      const currentIndex = items.findIndex((item) => item.title === activeTab);
      if (deltaX > 0 && currentIndex > 0) {
        setDirection('left');
        setTimeout(() => setActiveTab(items[currentIndex - 1].title), 0);
      } else if (deltaX < 0 && currentIndex < items.length - 1) {
        setDirection('right');
        setTimeout(() => setActiveTab(items[currentIndex + 1].title), 0);
      }
    }
  };

  const TabNavigationMemo = useMemo(
    () => (
      <TabNavigation>
        {items.map((item) => (
          <div
            key={item.title}
            style={{
              height: item.title === activeTab ? '56px' : 'auto',
              display: 'flex',
              width: '112px',
              alignItems: 'center',
            }}
          >
            {item.title === activeTab && (
              <DivDecoration height='100%' width='9' />
            )}

            <TabButton
              isActive={item.title === activeTab}
              onClick={() => handleTabClick(item.title)}
              disabled={item.disable}
            >
              {item.title === activeTab ? (
                <>
                  <TabsButtonDecoration />
                  <span>{item.title}</span>
                </>
              ) : (
                <span>{item.title}</span>
              )}
            </TabButton>

            {item.title === activeTab && (
              <DivDecoration height='100%' isReverse width='9' />
            )}
          </div>
        ))}
      </TabNavigation>
    ),
    [items, activeTab, handleTabClick]
  );

  const TabContentMemo = useMemo(
    () => (
      <TabContent>
        <AnimatePresence mode='wait' custom={direction}>
          {items.map(
            (item) =>
              item.title === activeTab && (
                <motion.div
                  key={item.title}
                  initial={
                    isFirstRender
                      ? false
                      : { opacity: 0, x: direction === 'right' ? 300 : -300 }
                  }
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: direction === 'right' ? -300 : 300 }}
                  transition={{
                    type: 'spring',
                    stiffness: 150,
                    damping: 20,
                    opacity: { duration: 0.2 },
                    x: { duration: 0.2 },
                  }}
                  style={{ height: '100%', width: '100%' }}
                >
                  <TabSegment style={{ height: '100%' }}>
                    {item.segment}
                  </TabSegment>
                </motion.div>
              )
          )}
        </AnimatePresence>
      </TabContent>
    ),
    [items, activeTab, direction, isFirstRender]
  );

  const AdditionalContent = useMemo(() => {
    return (
      <AdditionalTabContent>
        <AnimatePresence mode='wait' custom={direction}>
          {additionalItems?.map(
            (item) =>
              item.title === activeTab && (
                <motion.div
                  key={item.title}
                  initial={
                    isFirstRender
                      ? false
                      : { opacity: 0, x: direction === 'right' ? 300 : -300 }
                  }
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: direction === 'right' ? -300 : 300 }}
                  transition={{
                    type: 'spring',
                    stiffness: 150,
                    damping: 20,
                    opacity: { duration: 0.2 },
                    x: { duration: 0.2 },
                  }}
                  style={{ height: '100%', width: '100%' }}
                >
                  <TabSegment style={{ height: '100%' }}>
                    {item.segment}
                  </TabSegment>
                </motion.div>
              )
          )}
        </AnimatePresence>
      </AdditionalTabContent>
    );
  }, [additionalItems, activeTab, direction, isFirstRender]);

  return (
    <>
      <TabContainer
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        style={{
          height: tabHeight,
          flex: 1,
        }}
      >
        {TabNavigationMemo}
        {TabContentMemo}
      </TabContainer>
      {AdditionalContent}
    </>
  );
};
