import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'entities/user';

export const useMintingHook = (): {
  mintingStartedDate: Date;
  isSintReady: boolean;
  isMintingStarted: boolean;
  isMintModalShowed: boolean;
} => {
  const { mintDate, isMinted, mintModal } = useSelector(selectUser);

  const isSintMinted = useMemo(() => {
    const durationMintMinutes = process.env.REACT_APP_MINT_DURATION || 60000;

    // TODO REMOVE * 1000 when it will be fixed on server side.
    return (
      +new Date() >
        +new Date(+new Date(mintDate * 1000) + +durationMintMinutes) && isMinted
    );
  }, [isMinted, mintDate]);

  return {
    mintingStartedDate: new Date(mintDate * 1000),
    isSintReady: isSintMinted,
    isMintingStarted: isMinted,
    isMintModalShowed: mintModal,
  };
};
