import { ErrorContent } from 'feature/error';
import {
  CentredWrapper,
  Header,
  PageContent,
  PageWrapper,
} from 'shared/layout';

export const Error = () => {
  return (
    <PageWrapper>
      <CentredWrapper style={{ paddingBottom: 40 }}>
        <ErrorContent />
      </CentredWrapper>
    </PageWrapper>
  );
};
