import React from 'react';
import { toast, cssTransition, ToastContainer } from 'react-toastify';
import ToastContext from './ToastContext';
import { ToastProps } from './toast.types';
import { CloseIcon, SoonIcon, ToastIcon } from 'shared/icon';
import { StyledToast, CloseButton } from './Toast.styled';
import { colors } from 'app/colors';
import { NotificationType, useTelegram } from 'feature/auth';

const bounce = cssTransition({
  enter: 'animate__animated animate__bounceIn',
  exit: 'animate__animated animate__bounceOut',
});

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { triggerNotification } = useTelegram();
  const showToast: ToastProps['showToast'] = (message, type, options) => {
    const toastOptions = {
      autoClose: 2000,
      hideProgressBar: true,
      theme: 'dark',
      closeOnClick: true,
      transition: bounce,
      closeButton: (
        <CloseButton>
          <CloseIcon width={24} height={24} color={colors.blackColor} />
        </CloseButton>
      ),
      icon:
        type === 'success' ? (
          <ToastIcon color={colors.blackColor} />
        ) : type === 'error' ? (
          <ToastIcon color={colors.blackColor} />
        ) : type === 'warn' ? (
          <ToastIcon color={colors.blackColor} />
        ) : type === 'soon' ? (
          <SoonIcon />
        ) : undefined,
      ...options,
    };

    switch (type) {
      case 'success':
        triggerNotification(NotificationType.SUCCESS);
        toast.success(message, toastOptions);
        break;
      case 'error':
        triggerNotification(NotificationType.ERROR);
        toast.error(message, toastOptions);
        break;
      case 'warn':
        triggerNotification(NotificationType.WARNING);
        toast.warn(message, toastOptions);
        break;
      case 'soon':
        triggerNotification(NotificationType.WARNING);
        toast.info(message, {
          ...toastOptions,
          className: 'Toastify__toast--soon',
        });
        break;
      default:
        triggerNotification(NotificationType.WARNING);
        toast(message, toastOptions);
    }
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <StyledToast>
        <ToastContainer />
      </StyledToast>
    </ToastContext.Provider>
  );
};
